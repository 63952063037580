import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { AnalyticsStateView } from "../../../store/analytics/Analytics";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { NavigateFunction, useNavigate } from "react-router";
import { Dispatch } from "redux";
import { useView } from "../../../hooks/useView";

export abstract class DataOperation {
    operationId: string;
    displayName: string;
    icon: DataOperationIcon;
    priority: DataOperationPriority;
    context: DataOperationContext;
    category: DataOperationCategory;

    abstract canExecute(context: VizContext): boolean;
    abstract execute(context: VizContext): void;
}

export type VizContext = {
    projectId: string;
    viewId: string;
    view: AnalyticsStateView;
    react: {
        dispatch: Dispatch;
        navigate: NavigateFunction;
    };
    container: {
        hasInlineCropSelector: boolean;
    };
};

/** Utility function used to build a `VizContext` */
export function useVizContext(viewId: string): VizContext {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { general } = useSelector<ApplicationState, ApplicationState>((state) => state);
    const { view } = useView(viewId);

    return {
        projectId: general?.activeProjectId,
        viewId,
        view,
        react: { dispatch, navigate },
        container: { hasInlineCropSelector: false },
    };
}

export type DataOperationIcon =
    | {
          type: "fontawesome";
          icon: IconDefinition;
      }
    | {
          type: "image";
          url: string;
      };

export type DataOperationPriority = "primary" | "secondary" | "tertiary";

export type DataOperationContext = "global" | "entity" | "entityimage" | "selection";

export type DataOperationCategory =
    | "general"
    | "tags"
    | "export"
    | "import"
    | "ui"
    | "jump"
    | "entityManagement"
    | "entityManipulation"
    | "selections"
    | "imageManipulation";
