import { DateFormats } from "../../../utilities/dates";
import { Entity } from "../Entity";
import { MovementFragmentGroupEntityAdapter } from "../entityAdapters/FragmentGroupEntityAdapter";
import { DateTimeEntityInformationGenerator } from "../entityInformation/DateTimeEntityInformationGenerator";
import { FunctionEntityInformationGenerator } from "../entityInformation/FunctionEntityInformationGenerator";
import { AreaBoundsEntityFilter } from "../filters/AreaBoundsEntityFilter";
import { DateTimeEntityFilter } from "../filters/DateTimeEntityFilter";
import { OptionEntityFilter, StringPropertyOptionFilterPredicate } from "../filters/OptionEntityFilter";
import { CreateTagEventDataOperation } from "../operations/CreateTagEventDataOperation";
import { CreateTagObjectDataOperation } from "../operations/CreateTagObjectDataOperation";
import { DownloadVideoFrameOperation } from "../operations/DownloadVideoFrameOperation";
import { ExportClipToOvercastOperation } from "../operations/ExportClipToOvercastOperation";
import { JumpToReviewAtTimeOperation } from "../operations/JumpToReviewAtTimeOperation";
import { RefreshDataOperation } from "../operations/RefreshDataOperation";
import { DateTimeSorter } from "../sorters/DateTimeSorter";
import { DataDescription } from "./DataDescription";
import { EntityProperties } from "./EntityProperties";

export class StandardMovementDataDescription extends DataDescription {
    constructor() {
        super();

        this.type = "std-movement";
        this.infoGenerator = {
            titleGenerator: new DateTimeEntityInformationGenerator(
                EntityProperties.StartTime.key,
                DateFormats.yearMonthDayWithTimeSeconds,
                "Start Time",
            ),
            subtitleGenerator: new FunctionEntityInformationGenerator((e: Entity) => e.id, "ID"),
        };

        this.entityAdapter = new MovementFragmentGroupEntityAdapter(this);
        this.setProperties([
            EntityProperties.StartTime,
            EntityProperties.EndTime,
            EntityProperties.EventType,
            EntityProperties.Meta.Bounds,
        ]);
        this.filters = {
            eventType: new OptionEntityFilter(
                "Event Type",
                StringPropertyOptionFilterPredicate(EntityProperties.EventType.key),
                [
                    { value: "movement", label: "Movement" },
                    { value: "sound", label: "Sound" },
                ],
            ),
            startTime: new DateTimeEntityFilter("Start Time", EntityProperties.StartTime.key),
            endTime: new DateTimeEntityFilter("End Time", EntityProperties.StartTime.key),
            bounds: new AreaBoundsEntityFilter("Bounds", EntityProperties.Meta.Bounds.key),
        };
        this.defaultSorter = new DateTimeSorter("startTime", "Start Time", EntityProperties.StartTime.key);
        this.sorters = [this.defaultSorter, new DateTimeSorter("endTime", "End Time", EntityProperties.EndTime.key)];
        this.operations = [
            new RefreshDataOperation(),
            new JumpToReviewAtTimeOperation(),
            new CreateTagObjectDataOperation(),
            new CreateTagEventDataOperation(),
            new ExportClipToOvercastOperation(),
            new DownloadVideoFrameOperation(),
        ];
    }
}
