import { StandardActions, TagEventRelevances } from "cloud-core/tags/TagEvent";
import { TagObjectTypes } from "cloud-core/tags/TagObject";
import { DateFormats } from "../../../utilities/dates";
import { capitaliseFirst } from "../../../utilities/helpers";
import { MediaSource } from "../../media/MediaSource";
import { TAG_EVENT_STANDARD_ACTIONS, TagEvent } from "../../tags/TagEvent";
import { TagObject } from "../../tags/TagObject";
import { Entity } from "../Entity";
import { TagEventEntityAdapter } from "../entityAdapters/TagEventEntityAdapter";
import { DateTimeEntityInformationGenerator } from "../entityInformation/DateTimeEntityInformationGenerator";
import { DisplayStringEntityInformationGenerator } from "../entityInformation/DisplayStringEntityInformationGenerator";
import { ObjectListEntityProperty } from "../entityProperties/ObjectListEntityProperty";
import { DateTimeEntityFilter } from "../filters/DateTimeEntityFilter";
import {
    AvailableOption,
    DataOptionEntityFilter,
    OptionEntityFilter,
    OptionEntityFilterParameter,
    StringPropertyOptionFilterPredicate,
} from "../filters/OptionEntityFilter";
import { StringEntityFilter, StringPropertyGetter } from "../filters/StringEntityFilter";
import { RefreshDataOperation } from "../operations/RefreshDataOperation";
import { DateTimeSorter } from "../sorters/DateTimeSorter";
import { StringSorter } from "../sorters/StringSorter";
import { DataDescription } from "./DataDescription";
import { EntityProperties } from "./EntityProperties";

export class TagEventDataDescription extends DataDescription {
    constructor() {
        super();

        this.infoGenerator = {
            titleGenerator: new DisplayStringEntityInformationGenerator(EntityProperties.Name.key, "Name"),
            subtitleGenerator: new DateTimeEntityInformationGenerator(
                EntityProperties.StartTime.key,
                DateFormats.yearMonthDayWithTimeSeconds,
                "Start Time",
            ),
        };

        this.setProperties([
            EntityProperties.Name,
            EntityProperties.StartTime,
            EntityProperties.EndTime,
            EntityProperties.Meta.MediaSource,
            EntityProperties.EventSubjects,
            EntityProperties.EventAction,
            EntityProperties.EventObjects,
            EntityProperties.EventLocations,
            EntityProperties.Comments,
            EntityProperties.Relevance,
            EntityProperties.Meta.CreatedAt,
            EntityProperties.Meta.UpdatedAt,
            EntityProperties.Meta.FreeFormSearch,
        ]);
        this.defaultSorter = StringSorter.fromProperty(EntityProperties.Name);
        this.operations = [new RefreshDataOperation()];
        this.sorters = [
            this.defaultSorter,
            new StringSorter("action", "Action", EntityProperties.EventAction.key),
            new StringSorter("comments", "Comments", EntityProperties.Comments.key),
            new DateTimeSorter("startTime", "Start Time", EntityProperties.StartTime.key),
            new StringSorter("relevance", "Relevance", EntityProperties.Relevance.key),
            new StringSorter("mediaSource", "Media Source", EntityProperties.Meta.MediaSource.key),
        ];

        this.filters = {
            search: new StringEntityFilter("Search", StringPropertyGetter(EntityProperties.Meta.FreeFormSearch.key)),
            action: new OptionEntityFilter(
                "Action",
                StringPropertyOptionFilterPredicate(EntityProperties.EventAction.key),
                StandardActions.map((v) => ({ value: v, label: TAG_EVENT_STANDARD_ACTIONS[v].label })),
            ),
            type: new OptionEntityFilter(
                "Type",
                TagObjectTypesFilterPredicate(),
                TagObjectTypes.map((t) => ({ value: t, label: capitaliseFirst(t) })),
            ),
            relevance: new OptionEntityFilter(
                "Relevance",
                StringPropertyOptionFilterPredicate(EntityProperties.Relevance.key),
                TagEventRelevances.map((v) => ({ value: v, label: capitaliseFirst(v) })),
            ),
            mediaSource: new DataOptionEntityFilter(
                "Media Source",
                MediaSourceFilterPredicate(),
                MediaSourceFromDataOptionsGenerator,
            ),
            time: new DateTimeEntityFilter("Start Time", EntityProperties.StartTime.key),
            objects: new DataOptionEntityFilter(
                "Objects",
                EventTagObjectsFilterPredicate(),
                TagObjectsFromDataOptionsGenerator,
            ),
        };

        this.entityAdapter = new TagEventEntityAdapter(this);
    }
}

function TagObjectTypesFilterPredicate() {
    return (entities: Entity[], parameter: OptionEntityFilterParameter) => {
        return entities.filter((e) => {
            const event = e.sourceObject.object as TagEvent;
            const types: string[] = [...event.subjects.map((s) => s.type), ...event.objects.map((o) => o.type)];

            return parameter.options.some((o) => types.includes(o));
        });
    };
}

function MediaSourceFilterPredicate() {
    return (entities: Entity[], parameter: OptionEntityFilterParameter) => {
        return entities.filter((e) => {
            const mediaSource = e.sourceObject.mediaSource;

            return parameter.options.includes(mediaSource.mediaId);
        });
    };
}

function EventTagObjectsFilterPredicate() {
    return (entities: Entity[], parameter: OptionEntityFilterParameter) => {
        return entities.filter((e) => {
            const objectsProperty = e.properties[
                EntityProperties.EventObjects.key
            ] as ObjectListEntityProperty<TagObject>;
            const subjectsProperty = e.properties[
                EntityProperties.EventSubjects.key
            ] as ObjectListEntityProperty<TagObject>;

            const objects = objectsProperty?.value() ?? [];
            const subjects = subjectsProperty?.value() ?? [];

            return parameter.options.some((o) => {
                return objects.some((obj) => obj.objectId === o) || subjects.some((sub) => sub.objectId === o);
            });
        });
    };
}

function MediaSourceFromDataOptionsGenerator(entities: Entity[]): AvailableOption[] {
    const set = new Set<MediaSource>();

    for (const entity of entities) {
        if (entity.sourceObject.mediaSource != undefined) {
            set.add(entity.sourceObject.mediaSource);
        }
    }

    return Array.from(set).map((media) => ({
        value: media.mediaId,
        label: media.name,
        isFixed: false,
    }));
}

function TagObjectsFromDataOptionsGenerator(entities: Entity[]): AvailableOption[] {
    const set = new Set<TagObject>();

    for (const entity of entities) {
        const objectsProperty = entity.properties[
            EntityProperties.EventObjects.key
        ] as ObjectListEntityProperty<TagObject>;
        const subjectsProperty = entity.properties[
            EntityProperties.EventSubjects.key
        ] as ObjectListEntityProperty<TagObject>;

        const objects = objectsProperty?.value() ?? [];
        const subjects = subjectsProperty?.value() ?? [];

        for (const object of objects) {
            set.add(object);
        }

        for (const subject of subjects) {
            set.add(subject);
        }
    }

    return Array.from(set).map((tagObject) => ({
        value: tagObject.objectId,
        label: tagObject.name,
        isFixed: false,
    }));
}
