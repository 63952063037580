import { VizContainerProps } from "./VizContainer";
import "../Viz.scss";
import { EntityImageDisplay } from "./Shared/EntityImageDisplay";
import { useVizContext } from "../../../models/viz/operations/DataOperation";
import useMediaSources from "../../../hooks/useMediaSources";
import { useMemo } from "react";
import { FragmentGroup } from "../../../models/media/FragmentGroup";
import { useGetFragmentImagesQuery } from "../../../store/api/kinesense";
import { FileVideoFrameEntityImage } from "../../../models/viz/Entity";
import { useView } from "../../../hooks/useView";

export type VizPreviewProps = VizContainerProps;

export function VizPreview(props: VizPreviewProps) {
    const { view } = useView(props.viewId);
    const selectedEntity = view?.selectedEntity;

    const context = useVizContext(props.viewId);
    const { mediaId, activeMediaSource } = useMediaSources(props.viewId);
    const runId = activeMediaSource?.runs[activeMediaSource?.runs.length - 1]?.runId;

    // TODO: Revert changes when new backend for thumbnails is in place
    const midFragmentsMap = useMemo(() => {
        const midFragmentsMap = {};
        if (!context?.view?.entities?.length) {
            return midFragmentsMap;
        }

        for (const entity of context?.view?.entities ?? []) {
            const group = entity.sourceObject.object as FragmentGroup;
            if (group["fragmentGroupId"] !== undefined && group.info?.midFragmentId) {
                midFragmentsMap[group.fragmentGroupId] = [group.info.midFragmentId];
            }
        }
        return midFragmentsMap;
    }, [context?.view?.entities?.length]);

    const {
        data: fragmentImages,
        isSuccess: isSuccessFragmentImages,
        isFetching: isFetchingFragmentImages,
        isError: isErrorFragmentImages,
    } = useGetFragmentImagesQuery(
        {
            mediaId,
            runId,
            payload: { groups: midFragmentsMap },
        },
        { skip: mediaId === undefined || runId === undefined || !Object.keys(midFragmentsMap).length },
    );
    const hasLoadedFragmentImages = isSuccessFragmentImages && !isFetchingFragmentImages;

    const groupId = (selectedEntity?.sourceObject.object as FragmentGroup)?.fragmentGroupId;
    const shouldWaitForThumbnail = !isErrorFragmentImages && groupId && midFragmentsMap[groupId] !== undefined;
    const thumbnailUrl =
        shouldWaitForThumbnail && hasLoadedFragmentImages
            ? fragmentImages?.imageUrls[groupId]?.[midFragmentsMap[groupId][0]]
            : undefined;

    const entityImage = useMemo(() => {
        if (!selectedEntity) {
            return undefined;
        }

        if (shouldWaitForThumbnail) {
            if (thumbnailUrl) {
                return new FileVideoFrameEntityImage(thumbnailUrl);
            } else {
                return undefined;
            }
        } else {
            return selectedEntity.images.getMainImage();
        }
    }, [thumbnailUrl, selectedEntity?.id]);

    return (
        <div className="viz-preview viz-panel">
            <div className="viz-preview-title">{selectedEntity?.getTitle()}</div>
            <div className="viz-preview-thumbnail position-relative">
                {selectedEntity && <EntityImageDisplay entityImage={entityImage} />}
            </div>
        </div>
    );
}
