import { DateFormats } from "../../../utilities/dates";
import { Entity } from "../Entity";
import { ObjectClassificationFragmentGroupEntityAdapter } from "../entityAdapters/FragmentGroupEntityAdapter";
import { DateTimeEntityInformationGenerator } from "../entityInformation/DateTimeEntityInformationGenerator";
import { FunctionEntityInformationGenerator } from "../entityInformation/FunctionEntityInformationGenerator";
import { AreaBoundsEntityFilter } from "../filters/AreaBoundsEntityFilter";
import { DateTimeEntityFilter } from "../filters/DateTimeEntityFilter";
import { OptionEntityFilter, StringPropertyOptionFilterPredicate } from "../filters/OptionEntityFilter";
import { JumpToReviewAtTimeOperation } from "../operations/JumpToReviewAtTimeOperation";
import { RefreshDataOperation } from "../operations/RefreshDataOperation";
import { DateTimeSorter } from "../sorters/DateTimeSorter";
import { DataDescription } from "./DataDescription";
import { PropertyDescription } from "./PropertyDescription";
import { EntityProperties } from "./EntityProperties";

export class ObjectClassificationDataDescription extends DataDescription {
    constructor() {
        super();

        this.type = "object-classification";
        this.infoGenerator = {
            titleGenerator: new FunctionEntityInformationGenerator(
                (e: Entity) => e.properties[EntityProperties.ObjectCategory.key]?.display(),
                "Object Type",
            ),
            subtitleGenerator: new DateTimeEntityInformationGenerator(
                EntityProperties.StartTime.key,
                DateFormats.yearMonthDayWithTimeSeconds,
                "Start Time",
            ),
        };

        this.entityAdapter = new ObjectClassificationFragmentGroupEntityAdapter(this);

        this.setProperties([
            EntityProperties.StartTime,
            EntityProperties.EndTime,
            EntityProperties.EventType,
            EntityProperties.Meta.Bounds,
            EntityProperties.ObjectCategory,
        ]);

        this.filters = {
            eventType: new OptionEntityFilter(
                "Object Type",
                StringPropertyOptionFilterPredicate(EntityProperties.ObjectCategory.key),
                [
                    { value: "person", label: "Person" },
                    { value: "car", label: "Car" },
                    { value: "motorcycle", label: "Motorcycle" },
                    { value: "bicycle", label: "Bicycle" },
                    { value: "bus", label: "Bus" },
                    { value: "truck", label: "Truck" },
                    { value: "van", label: "Van" },
                    { value: "misc", label: "Misc" },
                ],
            ),
            startTime: new DateTimeEntityFilter("Start Time", EntityProperties.StartTime.key),
            endTime: new DateTimeEntityFilter("End Time", EntityProperties.StartTime.key),
            bounds: new AreaBoundsEntityFilter("Bounds", EntityProperties.Meta.Bounds.key),
        };

        this.defaultSorter = new DateTimeSorter("startTime", "Start Time", EntityProperties.StartTime.key);
        this.sorters = [this.defaultSorter, new DateTimeSorter("endTime", "End Time", EntityProperties.EndTime.key)];
        this.operations = [new RefreshDataOperation(), new JumpToReviewAtTimeOperation()];
    }
}
