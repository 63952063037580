import { Card, ListGroup } from "react-bootstrap";
import { ignoreDragEvent } from "../../../../components/dashboard/Dashboard";
import { useGetAllMediaQuery } from "../../../../store/api/kinesense";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import VideoListItem from "./VideoListItem";
import { motion } from "framer-motion";

function VideoList() {
    const projectId = useSelector((state: ApplicationState) => state.general.activeProjectId);
    const {
        data: allMediaSources,
        isSuccess,
        isFetching,
    } = useGetAllMediaQuery({ projectId }, { skip: projectId === undefined });

    const hasLoadedMediaSources = isSuccess && !isFetching;

    return (
        <Card as={motion.article} layout>
            <Card.Header as={motion.header} layout>
                <Card.Title as={motion.h5} layout className="m-1">
                    Videos
                </Card.Title>
            </Card.Header>

            <ListGroup variant="flush" as={motion.div} layout {...ignoreDragEvent}>
                <ListGroup.Item as={motion.header} layout>
                    <motion.span layout className="m-0 h6">
                        Name
                    </motion.span>
                    <motion.span layout className="m-0 h6">
                        Videos starts at
                    </motion.span>
                </ListGroup.Item>

                <motion.div layout className="items">
                    <ConditionalSpinner size="sm" isLoading={!hasLoadedMediaSources}>
                        {allMediaSources?.map((data) => <VideoListItem key={data.mediaId} data={data} />)}
                    </ConditionalSpinner>
                </motion.div>
            </ListGroup>
        </Card>
    );
}

export default VideoList;
