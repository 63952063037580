import dayjs from "dayjs";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MediaSource } from "../../../../../models/media/MediaSource";
import { ApplicationState } from "../../../../../store";
import {
    useAddMediaSourceMutation,
    useLinkFileToMediaSourceMutation,
    useRequestAnalysisMutation,
    useRequestIntegrationTransferMutation,
} from "../../../../../store/api/kinesense";
import { Notifications } from "../../../../../utilities/Notifications/Notifications";
import { DateFormats } from "../../../../../utilities/dates";
import { UploadUtil, UploadUtilOptions } from "../../fileUpload/UploadUtil";
import { ImportRequestState } from "../ImportWizard";
import "./ImportPages.scss";

interface MetadataEntryPageProps {
    request: ImportRequestState;
    getUploader: () => UploadUtil | undefined;
    createUploader: (options: UploadUtilOptions) => UploadUtil;
}

type AnalysisRequestState = "initial" | "uploading" | "transferring" | "requested" | "failed" | "complete";

function SummaryPage(props: MetadataEntryPageProps) {
    const { user, general } = useSelector((state: ApplicationState) => state);
    const [requestAnalysis] = useRequestAnalysisMutation();
    const [requestIntegrationTransfer] = useRequestIntegrationTransferMutation();
    const [linkFileToMediaSource] = useLinkFileToMediaSourceMutation();
    const [addMediaSource] = useAddMediaSourceMutation();
    const [uploadState, setUploadState] = useState<AnalysisRequestState>("initial");
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [isAnalyseButtonDisabled, setIsAnalyseButtonDisabled] = useState<boolean>(false);

    const createNewMediaSource = async (): Promise<MediaSource> => {
        return addMediaSource({
            name: props.request.name.data?.name,
            startsAt: props.request.name.data?.startTime?.getTime(),
            duration: 0,
            type: "video",
            metadata: props.request.metadata.data,
            projectId: general.activeProjectId,
        })
            .unwrap()
            .then((mediaSource) => {
                return mediaSource;
            })
            .catch((err) => {
                console.error("Failed to add media source", err);
                throw err;
            });
    };

    function handleError(error) {
        console.error(error);

        Notifications.notify(
            "Error with uploading file",
            "Please try again. If this error continues to occur, please contact customer support.",
            "warning",
        );

        setUploadState("failed");
        setUploadProgress(0);
        setIsAnalyseButtonDisabled(false);
    }

    const uploadFile = async (mediaSource: MediaSource) => {
        return new Promise<string>((resolve, reject) => {
            let fileId = "";
            const uploader = props.createUploader({
                file: props.request.source.data?.file,
                onStart: undefined,
                onUploadInitialized: (response) => {
                    console.log("upload initialized", response);
                    fileId = response.fileId;
                },
                onProgress: (progress) => {
                    console.log("progress", progress);
                    setUploadProgress(progress);
                },
                onComplete: () => {
                    console.log("complete");
                    setUploadProgress(100);
                    resolve(fileId);
                },
                onError: (e) => {
                    handleError(e);
                    reject(e);
                },
                getUser: () => {
                    return user;
                },
            });

            setUploadState("uploading");

            console.log("Uploading file");
            uploader.start().catch(reject);
        });
    };

    const getAnalysisProfile = (): string | undefined => {
        const profile = props.request.options.data?.standardOptions.analysisProfile;

        if (profile && typeof profile !== "string") {
            return undefined;
        }

        return profile as string;
    };

    const sendAnalysisRequest = async (mediaSource: MediaSource) => {
        const profile = getAnalysisProfile();

        const analysisRequest = {
            analyserId: props.request.algorithm.data?.analyserId,
            configuration: props.request.options.data,
            profile: profile,
            frameRateOverride: undefined,
            analysisBounds: undefined,
        };

        return requestAnalysis({
            mediaId: mediaSource.mediaId,
            analysisRequest: analysisRequest,
        })
            .unwrap()
            .then((response) => {
                console.log(response);
                setUploadState("requested");
            });
    };

    const handleRequestAnalysisClick = async () => {
        setIsAnalyseButtonDisabled(true);

        try {
            console.log("Creating new media source");
            const mediaSource = await createNewMediaSource();
            let fileId = "";

            switch (props.request.source.data?.type) {
                case "file":
                    fileId = await uploadFile(mediaSource);
                    break;
                case "integration":
                    console.log("Requesting file transfer");
                    setUploadState("transferring");
                    await requestIntegrationTransfer({
                        integrationId: "overcast",
                        integrationMediaId: props.request.source.data.integrationMediaSource.integrationMediaId,
                        mediaId: mediaSource.mediaId,
                    }).unwrap();
                    break;
            }

            // link file to media source
            console.log(`Linking file '${fileId}' to media source '${mediaSource.mediaId}'`);
            await linkFileToMediaSource({
                projectId: general.activeProjectId,
                mediaId: mediaSource.mediaId,
                fileId: fileId,
            });

            console.log("Requesting analysis");
            await sendAnalysisRequest(mediaSource);
        } catch (e) {
            handleError(e);
        }
    };

    const renderSource = () => {
        switch (props.request.source.data?.type) {
            case "file":
                return props.request.source.data?.file?.name;
            case "integration":
                return "Overcast: " + props.request.source.data?.integrationMediaSource.integrationMediaId;
            case "library":
                return props.request.source.data?.mediaSource.name;
        }
    };

    function renderUploadState() {
        switch (uploadState) {
            case "failed":
            case "initial":
                return (
                    <Button disabled={isAnalyseButtonDisabled} onClick={handleRequestAnalysisClick}>
                        Analyse Video
                    </Button>
                );
            case "uploading":
                return <Button disabled={true}>Uploading {Math.round(uploadProgress)}%...</Button>;
            case "transferring":
                return <Button disabled={true}>Transferring file...</Button>;
            case "requested":
                return <Button disabled={true}>Analysis Request Sent</Button>;
        }
    }

    return (
        <div>
            <Table>
                <tbody>
                    <tr>
                        <td>Source</td>
                        <td>{renderSource()}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{props.request.name.data?.name}</td>
                    </tr>
                    <tr>
                        <td>Start Time</td>
                        <td>
                            {dayjs(props.request.name.data?.startTime).format(DateFormats.yearMonthDayWithTimeSeconds)}
                        </td>
                    </tr>
                </tbody>
            </Table>
            {uploadState == "initial" && (
                <div className="mt-4 d-flex justify-content-center">
                    If this information is correct, click "Analyse Video" to begin the analysis. If not, click "Back" to
                    change the information.
                </div>
            )}
            <div className="mt-3 d-flex justify-content-center">{renderUploadState()}</div>
        </div>
    );
}

export default SummaryPage;
