import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContextMenu } from "../../../../hooks/useContextMenu";
import useUserConfig from "../../../../hooks/useUserConfig";
import { DataOperation, VizContext } from "../../../../models/viz/operations/DataOperation";
import { EntityImageDisplay } from "../Shared/EntityImageDisplay";
import VizListItemContextMenu from "./VizListItemContextMenu";
import { Entity, FileVideoFrameEntityImage } from "../../../../models/viz/Entity";
import { useMemo } from "react";

export interface VisListItemProps {
    isSelected: boolean;
    entity: Entity;
    onClick: (e: Entity) => void;
    context: VizContext;
    shouldWaitForThumbnail: boolean;
    thumbnailUrl: string | undefined;
}

export function LargeIconListItem(props: VisListItemProps) {
    const entityImage = useMemo(() => {
        if (props.shouldWaitForThumbnail) {
            if (props.thumbnailUrl) {
                return new FileVideoFrameEntityImage(props.thumbnailUrl);
            } else {
                return undefined;
            }
        } else {
            return props.entity.images.getMainImage();
        }
    }, [props.thumbnailUrl]);

    const { userConfig } = useUserConfig();
    const isMenuEnabled = userConfig.showCustomContextMenus;

    const { isShowingMenu, showMenu, hideMenu, menuCoords } = useContextMenu();

    const contextMenuOperations: DataOperation[] = [];
    if (props.context.view?.currentDataDescription !== undefined) {
        for (const operation of props.context.view.currentDataDescription.operations) {
            if (operation.context != "entity") {
                continue;
            }

            contextMenuOperations.push(operation);
        }
    }

    function handleOnContextMenu(e: React.MouseEvent) {
        if (!isMenuEnabled) {
            return;
        }

        e.preventDefault();

        // Select entity so that operations will apply to this entity
        props.onClick(props.entity);
        showMenu({ x: e.pageX, y: e.pageY });
    }

    return (
        <div
            onClick={() => props.onClick(props.entity)}
            onContextMenu={handleOnContextMenu}
            onMouseLeave={hideMenu}
            className={`viz-list-item${props.isSelected ? " selected" : ""}`}
            title={props.entity.getTitle()}
        >
            <VizListItemContextMenu
                show={isShowingMenu}
                operations={contextMenuOperations}
                coords={menuCoords}
                context={props.context}
            />

            {!isMenuEnabled && (
                <button
                    className="btn btn-secondary viz-list-item-show-context-menu fs-5"
                    onClick={(e) => {
                        // No stopping propagation, so the entity becomes selected
                        showMenu({ x: e.pageX, y: e.pageY });
                    }}
                >
                    <FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>
                </button>
            )}

            <div className="viz-list-item-thumbnail position-relative">
                <EntityImageDisplay entityImage={entityImage} />
            </div>
            <div className="viz-list-item-title">{props.entity.getTitle()}</div>
            <div className="viz-list-item-subtitle">{props.entity.getSubtitle()}</div>
        </div>
    );
}
