import { AreaBounds } from "cloud-core/spatial/Spatial";
import { AreaBoundsFilter } from "../../../views/viz/components/Filtering/AreaBoundsFilter";
import { Entity } from "../Entity";
import { EntityFilter } from "./EntityFilter";
import { AnalyticsStateView } from "../../../store/analytics/Analytics";
import { NumberListEntityProperty } from "../entityProperties/NumberListEntityProperty";

export type AreaBoundsEntityFilterParameter = { areas: AreaBounds[] };

export class AreaBoundsEntityFilter extends EntityFilter {
    propertyKey: string;

    constructor(displayName: string, propertyKey: string) {
        super(displayName);
        this.propertyKey = propertyKey;
    }

    areasAreOverlapping(first: AreaBounds, second: AreaBounds): boolean {
        return first[0] <= second[2] && first[1] <= second[3] && first[2] >= second[0] && first[3] >= second[1];
    }

    predicate(entities: Entity[], parameter: AreaBoundsEntityFilterParameter): Entity[] {
        if (parameter.areas.length == 0) {
            return entities;
        }

        return entities.filter((e) => {
            const entityRegion = (e.properties[this.propertyKey] as NumberListEntityProperty)?.value();

            if (entityRegion == undefined || entityRegion.length != 4) {
                return false;
            }

            for (const bound of parameter.areas) {
                if (this.areasAreOverlapping(bound, entityRegion as AreaBounds)) {
                    return true;
                }
            }

            return false;
        });
    }

    createComponent(
        filterId: string,
        view: AnalyticsStateView,
        options?: { hasInlineCropSelector: boolean },
    ): JSX.Element {
        return (
            <AreaBoundsFilter
                viewId={view.viewId}
                filterId={filterId}
                useInlineCropSelector={options?.hasInlineCropSelector ?? false}
            />
        );
    }
}
