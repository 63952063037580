import { Button, Modal, Form } from "react-bootstrap";
import { MutableRefObject, useRef } from "react";
import { useBasicAlerts } from "../../../../../components/BasicAlert";
import {
    AugmentationWithPayloadArgs,
    useAddUserMutation,
    useAdminAddUserMutation,
} from "../../../../../store/api/kinesense";
import { CreatedUserData } from "./NewUserCreatedModal";
import { AddUserProps } from "../AddUser";
import useUncontrolledFormUtils from "../../../../../hooks/useUncontrolledFormUtils";

export const FormField = {
    Name: "name",
    Username: "username",
    Email: "email",
} as const;
export type FormField = (typeof FormField)[keyof typeof FormField];

export type FormData = Record<FormField, string>;
export const EMPTY_FORM_VALUES = Object.values(FormField).reduce((acc, key) => {
    acc[key] = "";
    return acc;
}, {}) as Readonly<FormData>;

const FORM_VALIDATION: Record<FormField, object> = {
    [FormField.Name]: {
        minLength: 4,
        maxLength: 100,
    },
    [FormField.Username]: {
        minLength: 5,
        maxLength: 32,
        pattern: /^(?:(?![/\\])[\p{L}\p{M}\p{S}\p{N}\p{P}])+$/u.source,
        title: "Username must be between 5-32 characters long and not contain any whitespace or back/forward slashes",
    },
    [FormField.Email]: {
        maxLength: 254,
    },
} as const;

export type NewUserModalProps = AddUserProps & {
    isVisible: boolean;
    setIsVisible: (nextIsVisible: boolean) => void;
    setIsNewUserCreatedModalVisible: (nextIsVisible: boolean) => void;
    setCreatedUserData: (createdUserData: CreatedUserData | undefined) => void;
    attemptedUsernames: string[];
    setAttemptedUsernames: (setState: React.SetStateAction<string[]>) => void;
};

export default function NewUserModal(props: NewUserModalProps) {
    const modalTitleId = "createUserTitle";

    const [requestAddUser] = props.isForCurrentUsersOrganisation ? useAddUserMutation() : useAdminAddUserMutation();

    const { alerts, addAlert, clearAlerts } = useBasicAlerts();

    const inputRefs = Object.values(FormField).reduce((acc, key) => {
        acc[key] = useRef(null);
        return acc;
    }, {}) as Readonly<Record<FormField, MutableRefObject<HTMLInputElement>>>;

    const { getFormData, resetFormData } = useUncontrolledFormUtils<FormData>(inputRefs, EMPTY_FORM_VALUES);

    function hideModal() {
        props.setIsVisible(false);
        clearAlerts();
    }

    function handleOnSubmitUserInfo(event: React.FormEvent) {
        event.preventDefault();

        const formData = getFormData();

        if (props.attemptedUsernames.includes(formData[FormField.Username])) {
            addAlert({
                message:
                    "You have already attempted submitting a request with that username. Please change it before submitting another request.",
            });
            return;
        }

        const payload = JSON.stringify(formData);
        const args: AugmentationWithPayloadArgs = props.isForCurrentUsersOrganisation
            ? { payload }
            : { payload, organisationId: props.organisation.organisationId };

        requestAddUser(args)
            .unwrap()
            .then((data: CreatedUserData) => {
                props.setCreatedUserData({ ...formData, temporaryPassword: data.temporaryPassword });

                resetFormData();
                hideModal();
                props.setIsNewUserCreatedModalVisible(true);
            })
            .catch((error) => {
                switch (error?.data?.errorCode) {
                    case 33001:
                        props.setAttemptedUsernames((prevAttemptedUsernames) => {
                            return [...prevAttemptedUsernames, formData[FormField.Username]];
                        });
                        addAlert({
                            message: "A user with that username already exists. Please try a different username.",
                        });
                        break;
                    case 33003:
                        addAlert({
                            message:
                                "There were too many requests made. Please give it a couple of seconds before trying to create a new user again.",
                        });
                        break;
                    default:
                        addAlert({
                            message:
                                "There was an error while processing your request. Please contact customer support.",
                        });
                }
            });
    }

    return (
        <Modal id="newUserModal" show={props.isVisible} onHide={hideModal} aria-labelledby={modalTitleId} centered>
            <Modal.Header closeButton>
                <Modal.Title id={modalTitleId}>Create A New User</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleOnSubmitUserInfo}>
                <Modal.Body>
                    {alerts}

                    <Form.Group className="mb-3" controlId={FormField.Name}>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                            required
                            type="name"
                            placeholder="John Smith"
                            autoFocus
                            {...FORM_VALIDATION[FormField.Name]}
                            ref={inputRefs[FormField.Name]}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId={FormField.Username}>
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="jSmith"
                            {...FORM_VALIDATION[FormField.Username]}
                            ref={inputRefs[FormField.Username]}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId={FormField.Email}>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            required
                            type="email"
                            placeholder="j.smith@example.com"
                            {...FORM_VALIDATION[FormField.Email]}
                            ref={inputRefs[FormField.Email]}
                        />
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button type="submit" variant="success">
                        Create User
                    </Button>
                    <Button variant="secondary" onClick={hideModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
