import { faFilm, faIdCard, faPersonWalkingArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ConditionalSpinner from "../../../components/ConditionalSpinner";
import { useView } from "../../../hooks/useView";
import { ApplicationState } from "../../../store";
import * as AnalyticsStore from "../../../store/analytics/Analytics";
import { DataDescription } from "../../../models/viz/dataDescriptions/DataDescription";
import { motion, AnimatePresence } from "framer-motion";
import { AnalyticsStateView } from "../../../store/analytics/Analytics";
import { useMemo } from "react";
import useMediaSources from "../../../hooks/useMediaSources";
import OverlaySpinner from "../../../components/OverlaySpinner";
import { FragmentGroupDataSource } from "../../../models/viz/dataSources/FragmentGroupDataSource";

const SELECT_STYLES = {
    container: (provided) => ({
        ...provided,
        // TODO: Find way to either set the width to that of the longest option, or cut off overflowing text with ellipsis, to limit size changes
        minWidth: "12rem",
    }),
    valueContainer: (provided, _state) => ({
        ...provided,
        textAlign: "center",
    }),
    menu: (base) => ({
        ...base,
        width: "max-content",
        minWidth: "100%",
    }),
};

export interface VizMediaListProps {
    selectedViewId: string;
    projectViews: AnalyticsStateView[];
    mediaId: string;
    setMediaId: (_: string) => void;
}

type VizMediaListOptionType = { value: string; label: string; type: "existing" | "new" };

function VizMediaList(props: VizMediaListProps) {
    const dispatch = useDispatch();

    const { general } = useSelector((state: ApplicationState) => state);
    const projectId = general.activeProjectId;
    const { view, viewId } = useView(props.selectedViewId);

    const { allMediaSources, hasLoadedMediaSources } = useMediaSources(viewId);
    const isViewForCurrentMedia = view?.data.hasAssociatedMedia && view?.data.primaryMediaId == props.mediaId;

    // Split available options into existing views with a media source, and then other available media sources
    const { existingViewOptions, newViewOptions } = useMemo(() => {
        const existingViewOptions = [];
        const newViewOptions = [];

        // Keep track of media sources covered by existing views
        const usedMediaSources = [];

        for (const v of props.projectViews) {
            if (!v.data?.hasAssociatedMedia || v.data.source?.type !== "frag-group") {
                continue;
            }

            usedMediaSources.push(v.data.primaryMediaId);
            existingViewOptions.push({
                value: v.viewId,
                label: v.displayName ?? "???",
                type: "existing",
            });
        }

        for (const m of allMediaSources ?? []) {
            if (!usedMediaSources.includes(m.mediaId)) {
                newViewOptions.push({ value: m.mediaId, label: m.name, type: "new" });
            }
        }

        return { existingViewOptions, newViewOptions };
    }, [projectId, props.projectViews.length, allMediaSources?.length]);

    const selectedOption = existingViewOptions.find((v) => v.value === viewId);

    const options = [
        { label: "Recent", options: existingViewOptions },
        { label: "Videos", options: newViewOptions },
    ];

    function onOptionChange(option: VizMediaListOptionType) {
        // NOTE: while setting the media ID alone would update/create the view, manually dispatching here
        // prevents a (sometimes) significant delay
        switch (option.type) {
            case "existing": {
                dispatch(AnalyticsStore.actionCreators.setPrimaryView(option.value));
                const view = props.projectViews.find((v) => v.viewId == option.value);
                if (view?.data?.hasAssociatedMedia) {
                    props.setMediaId(view.data.primaryMediaId);
                }
                break;
            }
            case "new":
                dispatch(
                    AnalyticsStore.actionCreators.addView(
                        new FragmentGroupDataSource(
                            option.value,
                            [{ projectId: projectId, mediaId: option.value, runId: 0 }],
                            dispatch,
                        ),
                        true,
                        allMediaSources.find((m) => m.mediaId == option.value)?.name ?? "???",
                        projectId,
                    ),
                );
                props.setMediaId(option.value);
                break;
        }
    }

    function renderFormatOptionLabel(option: { value: string; label: string }) {
        return (
            <div>
                <FontAwesomeIcon icon={faFilm} />
                <span className="ms-2">{option.label}</span>
            </div>
        );
    }

    function renderDataDescriptionButton(d: DataDescription) {
        const isSelected = d.type == view?.currentDataDescription.type;

        let icon = faPersonWalkingArrowRight;
        let title = "Movement";
        switch (d.type) {
            case "object-classification":
                icon = faIdCard;
                title = "Object Classification";
        }

        return (
            <Button
                key={d.type}
                size="sm"
                variant="secondary"
                className={`btn-viz-data-description${isSelected ? " selected" : ""}`}
                title={title}
                onClick={() => dispatch(AnalyticsStore.actionCreators.setViewDataDescription(props.selectedViewId, d))}
            >
                <FontAwesomeIcon icon={icon} />
            </Button>
        );
    }

    return (
        <ConditionalSpinner isLoading={!hasLoadedMediaSources || viewId === undefined}>
            <div className="gap-3 d-flex">
                <div className="position-relative">
                    <Select
                        classNamePrefix="react-select"
                        options={options}
                        onChange={onOptionChange}
                        formatOptionLabel={renderFormatOptionLabel}
                        styles={SELECT_STYLES}
                        value={selectedOption}
                    />
                    <OverlaySpinner isLoading={!isViewForCurrentMedia} />
                </div>

                <AnimatePresence>
                    {view?.dataDescriptions.length > 1 && (
                        <motion.section
                            layout
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 20 }}
                            className="gap-1 d-flex"
                        >
                            {view?.dataDescriptions.map(renderDataDescriptionButton)}
                        </motion.section>
                    )}
                </AnimatePresence>
            </div>
        </ConditionalSpinner>
    );
}

export default VizMediaList;
