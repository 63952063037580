import NiceModal, { bootstrapDialog, useModal } from "@ebay/nice-modal-react";
import { useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import SpinnerButton from "../../../../components/SpinnerButton";
import { useAddProjectMutation } from "../../../../store/api/kinesense";
import { Notifications } from "../../../../utilities/Notifications/Notifications";

const AddProjectModal = NiceModal.create(() => {
    const modal = useModal();
    modal.keepMounted = true;

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [requestAddProject] = useAddProjectMutation();
    function handleOnSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (name == "") {
            return;
        }

        const payload = JSON.stringify({
            name,
            description,
        });

        setIsLoading(true);
        requestAddProject({ payload })
            .unwrap()
            .then(() => {
                Notifications.notify(
                    "New project created",
                    `A new project with name '${name}' has been successfully created`,
                );
                modal.hide();
            })
            .catch((e) => {
                Notifications.notify(
                    "Error creating project",
                    `The following error was encountered while attempting to create a new project: ${
                        e?.data?.message ?? e?.message ?? e?.error ?? "Unknown error"
                    }`,
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Modal {...bootstrapDialog(modal)} centered>
            <Modal.Header closeButton>Create a new project</Modal.Header>

            <Form onSubmit={handleOnSubmit}>
                <Modal.Body className="gap-2 d-flex flex-column">
                    <FloatingLabel id="name" controlId="name" label="Name">
                        <Form.Control
                            name="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </FloatingLabel>

                    <FloatingLabel controlId="description" label="Description">
                        <Form.Control
                            as="textarea"
                            name="description"
                            type="text"
                            value={description}
                            style={{ minHeight: "8em" }}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modal.hide}>
                        Cancel
                    </Button>
                    <SpinnerButton
                        isLoading={isLoading}
                        label="Create project"
                        buttonProps={{ type: "submit", variant: "success" }}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

export default AddProjectModal;
