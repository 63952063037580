import { VehicleTagObjectTypes } from "cloud-core/tags/TagObject";
import { PropertyDescription } from "./PropertyDescription";

export class EntityProperties {
    static StartTime = new PropertyDescription("StartTime", {
        displayName: "Start Time",
        isRequired: true,
        userRelevence: "basic",
        type: "date",
    });

    static EndTime = new PropertyDescription("EndTime", {
        displayName: "End Time",
        isRequired: true,
        userRelevence: "basic",
        type: "date",
    });

    static EventType = new PropertyDescription("EventType", {
        displayName: "Event Type",
        isRequired: true,
        userRelevence: "basic",
        type: "string",
    });

    static ObjectCategory = new PropertyDescription("ObjectCategory", {
        displayName: "Object Category",
        isRequired: true,
        userRelevence: "basic",
        confidenceKey: "ObjectCategoryConf",
        type: "string",
    });

    static Name = new PropertyDescription("Name", {
        displayName: "Name",
        isRequired: true,
        userRelevence: "basic",
        type: "string",
    });

    static Aliases = new PropertyDescription("Aliases", {
        displayName: "Aliases",
        isRequired: false,
        userRelevence: "detailed",
        type: "string",
        isList: true,
    });

    static Comments = new PropertyDescription("Comments", {
        displayName: "Comments",
        isRequired: false,
        userRelevence: "basic",
        type: "string",
    });

    static EventSubjects = new PropertyDescription("EventSubjects", {
        displayName: "Subject",
        isRequired: false,
        userRelevence: "basic",
        type: "object",
        isList: true,
    });

    static EventObjects = new PropertyDescription("EventObjects", {
        displayName: "Object",
        isRequired: false,
        userRelevence: "basic",
        type: "object",
        isList: true,
    });

    static EventAction = new PropertyDescription("EventAction", {
        displayName: "Action",
        isRequired: true,
        userRelevence: "basic",
        type: "string",
    });

    static EventLocations = new PropertyDescription("EventLocation", {
        displayName: "Location",
        isRequired: false,
        userRelevence: "basic",
        type: "object",
        isList: true,
    });

    static Relevance = new PropertyDescription("Relevance", {
        displayName: "Relevance",
        isRequired: false,
        userRelevence: "hidden",
        type: "string",
    });

    static Meta = {
        MediaSource: new PropertyDescription("MediaSource", {
            displayName: "Media Source",
            isRequired: false,
            userRelevence: "basic",
            type: "object",
        }),
        RunId: new PropertyDescription("RunId", {
            displayName: "Run Id",
            isRequired: false,
            userRelevence: "hidden",
            type: "string",
        }),
        Bounds: new PropertyDescription("Bounds", {
            displayName: "Bounds",
            isRequired: true,
            userRelevence: "detailed",
            type: "number",
            isList: true,
        }),
        CreatedAt: new PropertyDescription("CreatedAt", {
            displayName: "Created At",
            isRequired: false,
            userRelevence: "hidden",
            type: "date",
        }),
        UpdatedAt: new PropertyDescription("UpdatedAt", {
            displayName: "Updated At",
            isRequired: false,
            userRelevence: "hidden",
            type: "date",
        }),
        FreeFormSearch: new PropertyDescription("FreeFormSearch", {
            displayName: "Search",
            isRequired: false,
            userRelevence: "hidden",
            type: "string",
        }),
    };

    static Vehicle = {
        Type: new PropertyDescription("VehicleType", {
            displayName: "Vehicle Type",
            isRequired: false,
            userRelevence: "detailed",
            possibleValues: [...VehicleTagObjectTypes],
            type: "string",
        }),
        Colour: new PropertyDescription("VehicleColour", {
            displayName: "Vehicle Colour",
            isRequired: false,
            userRelevence: "detailed",
            type: "string",
        }),
        Model: new PropertyDescription("VehicleModel", {
            displayName: "Vehicle Model",
            isRequired: false,
            userRelevence: "detailed",
            type: "string",
        }),
        Make: new PropertyDescription("VehicleMake", {
            displayName: "Vehicle Make",
            isRequired: false,
            userRelevence: "detailed",
            type: "string",
        }),
        RegistrationPlate: new PropertyDescription("VehicleRegistrationPlate", {
            displayName: "Vehicle Registration Plate",
            isRequired: false,
            userRelevence: "detailed",
            type: "string",
        }),
    };

    static Group = {
        ApproxSize: new PropertyDescription("ApproxSize", {
            displayName: "Approx Size",
            isRequired: false,
            userRelevence: "detailed",
            type: "number",
        }),
    };

    static Location = {
        Address: new PropertyDescription("Address", {
            displayName: "Address",
            isRequired: false,
            userRelevence: "detailed",
            type: "string",
        }),
        PostCode: new PropertyDescription("PostCode", {
            displayName: "Post Code",
            isRequired: false,
            userRelevence: "detailed",
            type: "string",
        }),
        Country: new PropertyDescription("Country", {
            displayName: "Country",
            isRequired: false,
            userRelevence: "detailed",
            type: "string",
        }),
        Latitude: new PropertyDescription("Latitude", {
            displayName: "Latitude",
            isRequired: false,
            userRelevence: "detailed",
            type: "number",
        }),
        Longitude: new PropertyDescription("Longitude", {
            displayName: "Longitude",
            isRequired: false,
            userRelevence: "detailed",
            type: "number",
        }),
    };

    static Animal = {
        Species: new PropertyDescription("AnimalSpecies", {
            displayName: "Animal Species",
            isRequired: false,
            userRelevence: "detailed",
            type: "string",
        }),
        Breed: new PropertyDescription("AnimalBreed", {
            displayName: "Animal Breed",
            isRequired: false,
            userRelevence: "detailed",
            type: "string",
        }),
        Colour: new PropertyDescription("AnimalColour", {
            displayName: "Animal Colour",
            isRequired: false,
            userRelevence: "detailed",
            type: "string",
        }),
    };
}
