import { ListGroup, Spinner } from "react-bootstrap";
import { MediaSource } from "../../../models/media/MediaSource";
import { DateFormats } from "../../../utilities/dates";
import dayjs from "dayjs";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import { useGetAllTagEventsQuery, useGetStoredFileQuery } from "../../../store/api/kinesense";
import ThumbnailExtractor from "../../../utilities/ThumbnailExtractor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag, faVideoSlash, faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";

export interface ReviewVideoListItemProps {
    data: MediaSource;
    activeMediaId: string;
    setActiveMediaId: (_: SetStateAction<string>) => void;
    thumbnail: string | undefined;
    hasLoadedThumbnails: boolean;
}

function ReviewVideoListItem(props: ReviewVideoListItemProps) {
    const projectId = useSelector((state: ApplicationState) => state.general.activeProjectId);

    const isSelected = props.data.mediaId === props.activeMediaId;
    const hasAudio = props.data.files?.original?.audio !== undefined;
    const duration = dayjs?.duration(props.data.duration).format(DateFormats.durationTimeDaysToMilliseconds).split("#");

    function handleOnSelect(e: React.MouseEvent) {
        e.preventDefault();
        props.setActiveMediaId(props.data.mediaId);
    }

    const { data: tagEvents, isSuccess: hasLoadedTagEvents } = useGetAllTagEventsQuery({ projectId });
    const matchingEventsCount = useMemo(() => {
        if (!hasLoadedTagEvents) {
            return 0;
        }

        let count = 0;
        for (const event of tagEvents) {
            for (const occurrence of event.occurrences) {
                if (occurrence.mediaId == props.data.mediaId) {
                    count += 1;
                }
            }
        }
        return count;
    }, [hasLoadedTagEvents]);

    // BACKUP THUMBNAIL
    const [isLoadingBackupThumbnail, setIsLoadingBackupThumbnail] = useState(true);
    const [backupThumbnailSrc, setBackupThumbnailSrc] = useState<string>(undefined);

    const {
        data: storedFile,
        isSuccess: isSuccessStoredFile,
        isLoading: isLoadingStoredFile,
    } = useGetStoredFileQuery(
        { fileId: props.data.files.display?.fileId },
        { skip: props.thumbnail !== undefined || props.data?.files?.display?.fileId === undefined },
    );

    useEffect(() => {
        if (isLoadingStoredFile) {
            return;
        }
        if (!isSuccessStoredFile) {
            setIsLoadingBackupThumbnail(false);
            return;
        }

        ThumbnailExtractor.getThumbnail(storedFile.accessUrl.url, [0, 0, 1, 1], 0)
            .then((r) => {
                setBackupThumbnailSrc(r);
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                setIsLoadingBackupThumbnail(false);
            });
    }, [isSuccessStoredFile, isLoadingStoredFile]);

    function renderImage() {
        const width = "90px";
        if (!props.hasLoadedThumbnails || (props.thumbnail === undefined && isLoadingBackupThumbnail)) {
            return (
                <div style={{ width }} className="flex-shrink-0 d-flex justify-content-center align-items-center">
                    <Spinner size="sm" />
                </div>
            );
        } else if (props.thumbnail === undefined && backupThumbnailSrc === undefined) {
            return (
                <div
                    className="flex-shrink-0 bg-secondary-subtle d-flex justify-content-center align-items-center"
                    style={{ width }}
                    title="No preview"
                >
                    <FontAwesomeIcon icon={faVideoSlash} className="text-secondary-emphasis" />
                </div>
            );
        } else {
            const thumbnailSrc = props.thumbnail ?? backupThumbnailSrc;
            return (
                <div className="flex-shrink-0 align-self-center">
                    <img alt="thumbnail" src={thumbnailSrc} width={width} />
                </div>
            );
        }
    }

    return (
        <ListGroup.Item
            action
            onClick={handleOnSelect}
            className={`video-list-item position-relative d-flex align-items-stretch rounded border shadow-sm gap-3${
                isSelected ? " selected" : ""
            }`}
        >
            {renderImage()}

            <div className="gap-1 text-truncate text-nowrap flex-grow-1 d-flex flex-column">
                <span className="fw-bold text-truncate flex-fill text-nowrap">{props.data.name}</span>

                <div className="video-list-times">
                    <div className="gap-2 d-flex">
                        <span className="text-secondary">Start Time</span>
                        <span className="text-muted flex-fill text-nowrap">
                            {dayjs(props.data.startsAt).format(DateFormats.dayMonthYearWithTimeSeconds)}
                        </span>
                    </div>
                    <div className="gap-3 d-flex">
                        <span className="text-secondary">Duration</span>
                        <div>
                            <span className="text-muted">{duration[0]}</span>
                            <span className="text-secondary seconds-fraction">.{duration[1]}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="flex-shrink-0 justify-content-between d-flex flex-column align-items-end"
                style={{ width: "25px", fontSize: "0.9em", lineHeight: "0.9em" }}
            >
                <div>{hasAudio && <FontAwesomeIcon className="text-secondary" icon={faVolumeHigh} />}</div>
                {matchingEventsCount > 0 && (
                    <div className="gap-1 d-flex align-items-center text-secondary">
                        <span className="mb-1">{matchingEventsCount}</span>
                        <FontAwesomeIcon icon={faTag} />
                    </div>
                )}
            </div>
        </ListGroup.Item>
    );
}

export default ReviewVideoListItem;
