import { ColumnDef, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { VizListProps } from "./VizList";
import { useView } from "../../../../hooks/useView";
import { Entity } from "../../../../models/viz/Entity";
import { FormCheck, Table } from "react-bootstrap";
import { useMemo } from "react";
import { AnalyticsStateView } from "../../../../store/analytics/Analytics";
import { useDispatch } from "react-redux";
import * as AnalyticsStore from "../../../../store/analytics/Analytics";
import "./DetailList.scss";
import "../../Viz.scss";

export function DetailList(props: VizListProps) {
    const { view } = useView(props.viewId);
    const dispatch = useDispatch();
    const entities = view.filteredEntities;
    const columns = useMemo(() => createColumnDefinitions(view), [view.currentDataDescription.properties]);

    const table = useReactTable<Entity>({
        data: entities,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const itemClicked = (e: Entity) => {
        dispatch(AnalyticsStore.actionCreators.setSelectedEntity(props.viewId, e));
    };

    return (
        <div className="detail-table-container viz-panel">
            <Table className="detail-table" striped hover responsive>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => {
                        return (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th
                                            colSpan={header.colSpan}
                                            key={header.id}
                                            style={{ width: `${header.getSize()}pt` }}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id} onClick={() => itemClicked(row.original)}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}

function createColumnDefinitions(view: AnalyticsStateView) {
    const columns: ColumnDef<Entity>[] = [
        {
            id: "s",
            header: ({ table }) => (
                <FormCheck checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
            ),
            cell: ({ row }) => (
                <FormCheck type="checkbox" checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
            ),
            size: 10,
            enableResizing: false,
        },
        { id: "-", cell: () => "IMG", size: 20, enableResizing: false },
    ];

    for (const property of Object.values(view.currentDataDescription.properties)) {
        if (property.userRelevence !== "basic") {
            continue;
        }

        columns.push({
            id: property.key,
            header: property.displayName,
            accessorFn: (row) => row.properties[property.key]?.display(),
        });
    }

    return columns;
}
