import { Button, ButtonProps } from "react-bootstrap";
import OverlaySpinner from "./OverlaySpinner";

export interface SpinnerButtonProps {
    className?: string;
    label: string;
    isLoading: boolean;
    buttonProps: ButtonProps;
}

function SpinnerButton(props: SpinnerButtonProps) {
    const className = props.className ?? "";
    // Separate containers required to prevent weird CSS interactions
    return (
        <div className={className}>
            <div className="position-relative">
                <Button {...props.buttonProps} disabled={props.isLoading}>
                    <OverlaySpinner size="sm" isLoading={props.isLoading} />
                    {props.label}
                </Button>
            </div>
        </div>
    );
}

export default SpinnerButton;
