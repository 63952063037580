import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataOperation, DataOperationCategory, useVizContext } from "../../../../models/viz/operations/DataOperation";
import { useMemo } from "react";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import { DataOperationButton } from "../../../viz/components/Shared/DataOperationButton";

export interface VideoToolsSidebarProps {
    viewId: string;
    mediaId: string;
    isExpanded: boolean;
    setIsExpanded: (_: React.SetStateAction<boolean>) => void;
    videoRef: React.MutableRefObject<HTMLVideoElement>;
}

function VideoToolsSidebar(props: VideoToolsSidebarProps) {
    function toggleSidebar() {
        props.setIsExpanded((curr) => !curr);
    }

    const context = useVizContext(props.viewId);
    context.container.hasInlineCropSelector = true;

    const operations = useMemo(() => {
        const hiddenOperations = ["jumpToReviewAtTime", "refreshData"];
        const operationsSet = new Set<string>();
        const operationsMap = new Map<DataOperationCategory, DataOperation[]>();

        context.view?.dataDescriptions
            .flatMap((d) => d.operations)
            .filter((op) => !hiddenOperations.includes(op.operationId))
            .forEach((op) => {
                if (operationsSet.has(op.operationId)) {
                    return;
                } else {
                    operationsSet.add(op.operationId);

                    if (operationsMap.has(op.category)) {
                        operationsMap.get(op.category).push(op);
                    } else {
                        operationsMap.set(op.category, [op]);
                    }
                }
            });

        return operationsMap;
    }, [context.view?.dataDescriptions]);

    return (
        <div className="gap-4 video-sidebar video-sidebar-tools d-flex flex-column justify-content-between position-relative">
            <div className="gap-3 video-sidebar-content d-flex flex-column">
                <ConditionalSpinner isLoading={context.view?.dataDescriptions === undefined}>
                    {Array.from(operations).map(([category, operations], i) => (
                        <section key={i}>
                            <h4 className="fs-5">{categoryToDisplayName(category)}</h4>

                            {operations.map((operation) => (
                                <DataOperationButton
                                    key={operation.operationId}
                                    operation={operation}
                                    context={context}
                                />
                            ))}
                        </section>
                    ))}
                </ConditionalSpinner>
            </div>

            <button className="video-sidebar-expand-button" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={props.isExpanded ? faAngleDoubleRight : faAngleDoubleLeft} />
            </button>
        </div>
    );
}

function categoryToDisplayName(category: DataOperationCategory) {
    switch (category) {
        case "general":
            return "General";
        case "export":
            return "Export";
        case "import":
            return "Import";
        case "jump":
            return "Go To";
        case "tags":
            return "Tags";
        default:
            return category;
    }
}

export default VideoToolsSidebar;
