import { useView } from "../../../hooks/useView";
import { VizContainerProps } from "./VizContainer";
import "../Viz.scss";

export type VizDetailsProps = VizContainerProps;

export function VizDetails(props: VizDetailsProps) {
    const { view } = useView(props.viewId);
    const selectedEntity = view?.selectedEntity;

    const isBasicProperty = ([key, _]) =>
        selectedEntity.dataDescription.properties[key] &&
        selectedEntity.dataDescription.properties[key].userRelevence == "basic";

    return (
        <div className="viz-details viz-panel">
            <h1>Details</h1>
            {Object.entries(selectedEntity?.properties ?? {})
                .filter(isBasicProperty)
                .map(([key, value]) => [selectedEntity.dataDescription.properties[key].displayName, value?.display?.()])
                .filter(([_, displayValue]) => displayValue != undefined && displayValue.length > 0)
                .map(([title, displayValue], i) => (
                    <div key={i}>
                        <div>{title}</div>
                        <div className="text-muted">{displayValue}</div>
                    </div>
                ))}
        </div>
    );
}
