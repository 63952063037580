import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import { useView } from "../../../../hooks/useView";
import { VizContainerProps } from "../VizContainer";
import { LargeIconList } from "./LargeIconList";
import { DetailList } from "./DetailList";
import "./VizList.scss";

export type VizListProps = VizContainerProps;

export function VizList(props: VizListProps) {
    const { view } = useView(props.viewId);

    const renderList = () => {
        switch (view?.representation) {
            case "large-icons":
                return <LargeIconList viewId={props.viewId} />;
            case "detail-list":
                return <DetailList viewId={props.viewId} />;
        }
    };

    return (
        <div className="viz-list">
            <ConditionalSpinner isLoading={view?.data.state != "loaded"}>{renderList()}</ConditionalSpinner>
        </div>
    );
}
