import NiceModal, { bootstrapDialog, useModal } from "@ebay/nice-modal-react";
import { VizContext } from "../../../models/viz/operations/DataOperation";
import { Button, Form, Modal } from "react-bootstrap";
import { FormEvent, useEffect, useState } from "react";
import { AreaBounds } from "cloud-core/spatial/Spatial";
import { useGetStoredFileQuery } from "../../../store/api/kinesense";
import { Notifications } from "../../../utilities/Notifications/Notifications";
import ThumbnailExtractor from "../../../utilities/ThumbnailExtractor";
import useAsyncCallback from "../../../hooks/useAsyncCallback";
import ConditionalSpinner from "../../ConditionalSpinner";
import dayjs from "dayjs";
import { DateFormats } from "../../../utilities/dates";
import { tryExtractErrorMessage } from "../../../utilities/helpers";

export interface DownloadVideoFrameModalProps {
    context: VizContext;
    selection?: AreaBounds;
}

const DownloadVideoFrameModal = NiceModal.create((props: DownloadVideoFrameModalProps) => {
    const bounds = props.selection ?? [0, 0, 1, 1];
    const entity = props.context.view?.selectedEntity ?? props.context.view?.entities[0];
    const media = entity?.sourceObject?.mediaSource;
    const fileId = media?.files?.display?.fileId;
    const timestamp = props.context.view.cursor - media.startsAt;

    const [fileName, setFileName] = useState(
        `${media.name}_${dayjs(props.context.view.cursor).format(
            DateFormats.fileNameYearMonthDaysWithTimeMilliseconds,
        )}`,
    );
    const [url, setUrl] = useState<string>(undefined);

    const { data: storedFile, isSuccess: hasLoadedFile } = useGetStoredFileQuery({ fileId });
    const isLoading = !hasLoadedFile || url === undefined;

    const modal = useModal();

    useAsyncCallback(
        async () => {
            // Generate image data URL from file and time stamp
            const newUrl = await ThumbnailExtractor.getThumbnail(storedFile.accessUrl.url, bounds, timestamp).catch(
                (e) => {
                    Notifications.notify(
                        "Error extracting section of video frame",
                        `The following error was encountered while attempting to extract a section of a video frame: ${tryExtractErrorMessage(
                            e,
                        )}`,
                    );
                },
            );

            if (newUrl) {
                window.URL.revokeObjectURL(url);
                setUrl(newUrl);
            }
        },
        [hasLoadedFile],
        {
            skip: !hasLoadedFile,
        },
    );

    useEffect(() => {
        // Cleanup data URL
        return () => window.URL.revokeObjectURL(url);
    }, [url]);

    function handleOnSubmit(e: FormEvent) {
        e.preventDefault();

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();

        modal.hide();
    }

    return (
        <Modal centered {...bootstrapDialog(modal)} onHide={modal.hide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5 className="">Download image</h5>
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleOnSubmit}>
                <Modal.Body>
                    <ConditionalSpinner isLoading={isLoading}>
                        <div className="text-center">
                            <img className="img-fluid" src={url} alt="Image preview" />
                        </div>
                    </ConditionalSpinner>

                    <hr className="mt-4" />

                    <Form.Group>
                        <Form.Label>Output file name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            value={fileName}
                            onChange={(e) => setFileName(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modal.hide}>
                        Cancel
                    </Button>
                    <span title="Download image">
                        <Button disabled={isLoading} type="submit">
                            Download
                        </Button>
                    </span>
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

export default DownloadVideoFrameModal;
