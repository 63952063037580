import { MutableRefObject, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user/User";
import SpinnerButton from "../../components/SpinnerButton";

export interface LoginProps {
    onSubmitCallbackRef: MutableRefObject<() => void>;
}

function Login(props: LoginProps) {
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.user);
    const isUpdatingAuth = user?.loginIssueParams?.isUpdatingAuth ?? false;

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    function handleRevealPasswordClicked(event: React.ChangeEvent<HTMLInputElement>) {
        setIsPasswordVisible(event.target.checked);
    }

    function handleUsernameChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setUsername(event.target.value);
    }

    function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setPassword(event.target.value);
    }

    if (props.onSubmitCallbackRef?.current) {
        props.onSubmitCallbackRef.current = () => dispatch(UserStore.actionCreators.logInUser(username, password));
    }

    return (
        <fieldset className="gap-1 d-flex flex-column">
            <Form.Control
                type="text"
                value={username}
                placeholder="Username"
                onChange={handleUsernameChange}
                required
            />
            <Form.Control
                type={isPasswordVisible ? "text" : "password"}
                value={password}
                placeholder="Password"
                onChange={handlePasswordChange}
                required
            />

            {user?.loginIssue != undefined && <span className="text-danger">Incorrect username or password.</span>}

            <Form.Check type="checkbox" className="mt-2" label="Show Password" onChange={handleRevealPasswordClicked} />

            <SpinnerButton
                className="mt-3 text-center"
                buttonProps={{ type: "submit" }}
                label="Login"
                isLoading={isUpdatingAuth}
            />
        </fieldset>
    );
}

export default Login;
