import { AreaBounds } from "cloud-core/spatial/Spatial";
import { PercentCrop } from "react-image-crop";

/** Capitalises the first character of a given string */
export function capitaliseFirst(input: string) {
    if (input === undefined || input.length === 0) {
        return input;
    }

    return input.charAt(0).toUpperCase() + input.slice(1);
}

/** Returns true if number is in the range of the given limits. Note that the lower limit
 * is inclusive and the upper limit is exclusive (not greedy). */
export function isInRange(subject: number, limits: [number, number]) {
    limits.sort((a, b) => a - b); // ensure correct order for lower limit and upper limit

    return subject >= limits[0] && subject < limits[1];
}

/** Takes a string as input and returns a string that is valid for a HTML id by replacing any spaces / symbols with "-" */
export function cleanId(input: string) {
    return input.replace(/\W+/g, "-");
}

/** Throws an error if an AreaBounds object is undefined, null, or does not have the correct number of elements */
export function assertBoundsValid(bounds: AreaBounds | undefined) {
    if (!bounds || bounds.length != 4) {
        throw new Error(`The object passed is not a valid AreaBounds. Object: ${bounds}`);
    }
}

/** Returns true if the given AreaBounds object is ordered correctly i.e. x1 <= x2 and y1 <= y2  */
export function isBoundsElementsValid(bounds: AreaBounds) {
    return bounds[0] < bounds[2] && bounds[1] < bounds[3];
}

/** Converts a `PercentCrop` object to an AreaBounds */
export function convertCropToBounds(crop: PercentCrop): AreaBounds {
    return [crop.x / 100, crop.y / 100, (crop.x + crop.width) / 100, (crop.y + crop.height) / 100];
}

/** Attempts to extract an error message from different error types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tryExtractErrorMessage(e: any) {
    e?.data?.message ?? e?.message ?? e?.error ?? "Unknown error";
}
