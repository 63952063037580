import { Form } from "react-bootstrap";
import { Analyser } from "../../../../../models/analysis/Config";
import { ImportWizardPagePropsBase } from "../ImportWizard";
import { useEffect, useState } from "react";

interface AlgorithmSelectionPageProps extends ImportWizardPagePropsBase {
    analysers: Analyser[];
    selectedAnalyser: Analyser | undefined;
}

function AlgorithmSelectionPage(props: AlgorithmSelectionPageProps) {
    const [selectedAlgorithm, setSelectedAlgorithm] = useState<Analyser | undefined>(
        props.selectedAnalyser ?? props.analysers[0],
    );

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedId = e.target.value;
        const selected = props.analysers.find((analyser) => analyser.analyserId === selectedId);
        setSelectedAlgorithm(selected);
    };

    useEffect(() => {
        props.validityChanged(true);
    }, []);

    useEffect(() => {
        props.dataChanged(selectedAlgorithm);
    }, [selectedAlgorithm]);

    return (
        <div>
            {props.analysers.map((analyser, i) => (
                <div className="mb-3" key={i}>
                    <Form.Check id={analyser.analyserId} type="radio">
                        <Form.Check.Input
                            type="radio"
                            value={analyser.analyserId}
                            name="algo"
                            onChange={handleRadioChange}
                            checked={analyser === selectedAlgorithm}
                        />
                        <Form.Check.Label>
                            <div>
                                <div>{analyser.displayName}</div>
                                <div>{analyser.description}</div>
                            </div>
                        </Form.Check.Label>
                    </Form.Check>
                </div>
            ))}
        </div>
    );
}

export default AlgorithmSelectionPage;
