import React, { MutableRefObject, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Modal } from "react-bootstrap";
import { AugmentationWithPayloadArgs, useAdminAddOrganisationMutation } from "../../../../store/api/kinesense";
import { Notifications } from "../../../../utilities/Notifications/Notifications";
import useUncontrolledFormUtils from "../../../../hooks/useUncontrolledFormUtils";

export const FormField = {
    Name: "name",
    Country: "country",
} as const;
export type FormField = (typeof FormField)[keyof typeof FormField];

export type FormData = Record<FormField, string>;
export const EMPTY_FORM_VALUES = Object.values(FormField).reduce((acc, key) => {
    acc[key] = "";
    return acc;
}, {}) as Readonly<FormData>;

const formValidation = {
    [FormField.Name]: {
        minLength: 4,
        maxLength: 24,
    },
    [FormField.Country]: {
        minLength: 2,
        maxLength: 24,
    },
} as const;

function AddOrganisation() {
    const [isOrgInfoModalVisible, setIsOrgInfoModalVisible] = useState(false);

    const [requestAddOrganisation] = useAdminAddOrganisationMutation();

    const modalTitleId = "addOrgTitle";

    const showOrgInfoModal = () => setIsOrgInfoModalVisible(true);

    const hideOrgInfoModal = () => setIsOrgInfoModalVisible(false);

    const inputRefs = Object.values(FormField).reduce((acc, key) => {
        acc[key] = useRef(null);
        return acc;
    }, {}) as Readonly<Record<FormField, MutableRefObject<HTMLInputElement>>>;
    const { getFormData, resetFormData } = useUncontrolledFormUtils<FormData>(inputRefs, EMPTY_FORM_VALUES);

    function handleOnSubmitOrgInfoForm(event: React.FormEvent) {
        event.preventDefault();

        const formData = getFormData();

        const args: AugmentationWithPayloadArgs = { payload: JSON.stringify(formData) };

        requestAddOrganisation(args)
            .unwrap()
            .then(() =>
                Notifications.notify(
                    "Organisation Created",
                    `The ${formData[FormField.Name]} organisation has been created successfully`,
                    "info",
                ),
            )
            .catch((e) => Notifications.notify("Error with creating new organisation", JSON.stringify(e), "important"));

        hideOrgInfoModal();
        resetFormData();
    }

    return (
        <section className="add-organisation">
            <Button variant="success" onClick={showOrgInfoModal}>
                <FontAwesomeIcon icon={faPlusCircle} className="me-1" /> Add Organisation
            </Button>

            <Modal
                id="newOrganisationInfoModal"
                show={isOrgInfoModalVisible}
                onHide={hideOrgInfoModal}
                aria-labelledby={modalTitleId}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id={modalTitleId}>Add a New Organisation</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleOnSubmitOrgInfoForm}>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId={FormField.Name}>
                            <Form.Label>Organisation Name</Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Kinesense Ltd."
                                autoFocus
                                {...formValidation[FormField.Name]}
                                ref={inputRefs[FormField.Name]}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId={FormField.Country}>
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Ireland"
                                {...formValidation.country}
                                ref={inputRefs[FormField.Country]}
                            />
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button type="submit" variant="success">
                            Add Organisation
                        </Button>
                        <Button type="button" variant="secondary" onClick={hideOrgInfoModal}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </section>
    );
}

export default AddOrganisation;
