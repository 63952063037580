import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { MediaSource } from "../../media/MediaSource";
import { TagEvent } from "../../tags/TagEvent";
import { TagObject } from "../../tags/TagObject";
import { Entity, EntityImageCollection, FileVideoFrameEntityImage } from "../Entity";
import { DataDescription } from "../dataDescriptions/DataDescription";
import { PropertyDescription } from "../dataDescriptions/PropertyDescription";
import { EntityProperties } from "../dataDescriptions/EntityProperties";
import { DateEntityProperty } from "../entityProperties/DateEntityProperty";
import { EntityProperty } from "../entityProperties/EntityProperty";
import { ObjectEntityProperty } from "../entityProperties/ObjectEntityProperty";
import { ObjectListEntityProperty } from "../entityProperties/ObjectListEntityProperty";
import { StringEntityProperty } from "../entityProperties/StringEntityProperty";
import { EntityAdapter } from "./EntityAdapter";
import { AreaBounds } from "cloud-core/spatial/Spatial";

type TagEventWithMedia = { tagEvent: TagEvent; media: MediaSource };

export class TagEventEntityAdapter implements EntityAdapter<TagEventWithMedia> {
    dataDescription: DataDescription;

    constructor(dataDescription: DataDescription) {
        this.dataDescription = dataDescription;
    }

    setDataDescription(dataDescription: DataDescription): void {
        this.dataDescription = dataDescription;
    }

    generateFreeFormSearchString(event: TagEvent): string {
        return `${event.name} ${event.comments}`;
    }

    convertToEntity({ tagEvent, media }: { tagEvent: TagEvent; media: MediaSource }): Entity {
        const props = this.dataDescription.properties;
        const ep = EntityProperties;
        const action = tagEvent.action.type === "standard" ? tagEvent.action.action : tagEvent.action.name;
        const objectsToNameList = (objects: TagObject[]) => objects.map((o) => o.name).join(", ");

        const properties: [PropertyDescription, EntityProperty<unknown>][] = [
            [props[ep.Name.key], new StringEntityProperty(TagEvent.createSummary(tagEvent))],
            [props[ep.Comments.key], new StringEntityProperty(tagEvent.comments)],
            [props[ep.EventAction.key], new StringEntityProperty(action)],
            [props[ep.EventSubjects.key], new ObjectListEntityProperty(tagEvent.subjects, objectsToNameList)],
            [props[ep.EventObjects.key], new ObjectListEntityProperty(tagEvent.objects, objectsToNameList)],
            [props[ep.EventLocations.key], new ObjectListEntityProperty(tagEvent.locations, objectsToNameList)],
            [props[ep.StartTime.key], new DateEntityProperty(dayjs(tagEvent.startsAt).toDate())],
            [props[ep.EndTime.key], new DateEntityProperty(dayjs(tagEvent.startsAt + tagEvent.duration).toDate())],
            [props[ep.Relevance.key], new StringEntityProperty(tagEvent.relevance)],
            [props[ep.Meta.MediaSource.key], new ObjectEntityProperty(media, (m) => m.name)],
            [props[ep.Meta.CreatedAt.key], new DateEntityProperty(new Date(tagEvent.createdAt))],
            [props[ep.Meta.UpdatedAt.key], new DateEntityProperty(new Date(tagEvent.updatedAt))],
            [props[ep.Meta.FreeFormSearch.key], new StringEntityProperty(this.generateFreeFormSearchString(tagEvent))],
        ];

        const entityProperties = Object.fromEntries(properties.map(([prop, value]) => [prop.key, value]));

        const images = [];
        if (media?.files.display?.fileId !== undefined) {
            let timestamp = 0;
            if (tagEvent.startsAt !== undefined && media?.startsAt !== undefined) {
                timestamp = tagEvent.startsAt - media.startsAt;
            }
            images.push(
                new FileVideoFrameEntityImage({
                    timestamp,
                    bounds: [0, 0, 1, 1] as AreaBounds,
                    fileId: media.files.display.fileId,
                }),
            );
        }

        return new Entity({
            id: nanoid(16),
            sourceObject: {
                id: tagEvent.eventId,
                mediaSource: media,
                runId: undefined,
                mediaSourceFrameOffset: undefined,
                projectId: tagEvent.projectId,
                object: tagEvent,
            },
            dataDescription: this.dataDescription,
            images: new EntityImageCollection(images),
            properties: entityProperties,
        });
    }
}
