import { useGetStoredFileQuery } from "../../../../store/api/kinesense";
import { useEffect, useState } from "react";
import { EntityImage, FileVideoFrameEntityImage } from "../../../../models/viz/Entity";
import ThumbnailExtractor from "../../../../utilities/ThumbnailExtractor";
import LoadingLabel from "../../../../components/LoadingLabel";

export interface EntityImageDisplayProps {
    entityImage: EntityImage;
}

export function EntityImageDisplay(props: EntityImageDisplayProps) {
    const hasNoImage = (props.entityImage !== undefined && !props.entityImage.hasImage()) ?? true;
    const { data: storedFile, isError } = useGetStoredFileQuery(
        { fileId: props.entityImage?.fileId },
        { skip: props.entityImage?.fileId === undefined },
    );

    const thumbnailUrl = (props.entityImage as FileVideoFrameEntityImage)?.thumbnailUrl;
    const [isLoading, setIsLoading] = useState(thumbnailUrl === undefined);
    const [thumbnailSrc, setThumbnailSrc] = useState<string | undefined>(thumbnailUrl);
    useEffect(() => {
        if (!thumbnailUrl) {
            return;
        }

        setThumbnailSrc(thumbnailUrl);
        setIsLoading(false);
    }, [thumbnailUrl]);

    useEffect(() => {
        if (storedFile?.accessUrl?.url && props.entityImage?.origin == "videoFileFrame") {
            const time = props.entityImage.timestamp;
            const bounds = props.entityImage.bounds;

            ThumbnailExtractor.getThumbnail(storedFile.accessUrl.url, bounds, time)
                .then((r) => {
                    setThumbnailSrc(r);
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [storedFile, props.entityImage]);

    return isLoading ? (
        <LoadingLabel isLoading={true} />
    ) : hasNoImage || isError ? (
        <div aria-label="No image available" className="no-image">
            No image
        </div>
    ) : (
        <img src={thumbnailSrc} style={{ height: "100%", maxWidth: "100%", margin: "auto", display: "block" }} />
    );
}
