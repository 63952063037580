import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { IntegrationMediaSource } from "../../../../../models/media/MediaSource";
import { ImportWizardPagePropsBase, SourceSelectionPageData } from "../ImportWizard";
import "./ImportPages.scss";
import { useGetAllIntegrationMediaQuery } from "../../../../../store/api/kinesense";
import ConditionalSpinner from "../../../../../components/ConditionalSpinner";
import BasicAlert from "../../../../../components/BasicAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";

interface SourceSelectionPageProps extends ImportWizardPagePropsBase {
    selectedFile: File | undefined;
    value: SourceSelectionPageData;
}

function SourceSelectionPage(props: SourceSelectionPageProps) {
    const [selectedFile, setSelectedFile] = useState<File | undefined>(props.selectedFile);
    const isFileSelected = selectedFile !== undefined;
    const [isSupported, setIsSupported] = useState<boolean>(true);
    const [showOvercastLibrary, setShowOvercastLibrary] = useState<boolean>(false);
    const {
        data: overcastData,
        isLoading: overcastIsLoading,
        isSuccess: overcastIsSuccess,
    } = useGetAllIntegrationMediaQuery({ integrationId: "overcast" }, { skip: !showOvercastLibrary });

    const videoRef = useRef<HTMLVideoElement>(null);

    async function handleFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];

        if (file) {
            console.log(file);

            setSelectedFile(file);

            props.dataChanged({ type: "file", file });
        }
    }

    function handleOvercastMediaSelected(media: IntegrationMediaSource) {
        props.dataChanged({
            type: "integration",
            integrationMediaSource: media,
        });

        props.validityChanged(true);
    }

    function handleHideOvercastSelection() {
        setSelectedFile(undefined);
        props.validityChanged(false);
        setShowOvercastLibrary(false);
    }

    function handleShowOvercastSelection() {
        setSelectedFile(undefined);
        props.validityChanged(false);
        setShowOvercastLibrary(true);
    }

    function renderSelectedSource() {
        return (
            <div className="d-flex justify-content-center">
                <video
                    className={!isSupported ? "d-none" : ""}
                    style={{ maxHeight: 500, minHeight: 300 }}
                    ref={videoRef}
                    controls
                />

                {!isSupported && (
                    <aside className="alert alert-danger">
                        The selected video format is not compatible with your browser. No preview available.
                    </aside>
                )}
            </div>
        );
    }

    function renderOvercastSource(media: IntegrationMediaSource) {
        return (
            <div key={media.integrationMediaId}>
                <div>
                    <img width={250} src={media.preview.source} />
                </div>
                <div>
                    <span>{media.name}</span>
                    <Form.Check
                        key={media.integrationMediaId}
                        type="radio"
                        onChange={() => {
                            handleOvercastMediaSelected(media);
                        }}
                    />
                </div>
            </div>
        );
    }

    function renderOvercastSourceLibrary() {
        return (
            <div className="gap-2 d-flex flex-column">
                <section className="d-flex">
                    <Button
                        variant="outline-secondary"
                        className="m-0 bg-transparent border-0"
                        title="Return to file selection"
                        onClick={handleHideOvercastSelection}
                    >
                        <FontAwesomeIcon icon={faLongArrowLeft} />
                    </Button>

                    <h5 className="m-0 d-flex align-items-center">Overcast Library</h5>
                </section>
                <section>
                    <ConditionalSpinner isLoading={overcastIsLoading}>
                        {overcastIsSuccess ? (
                            overcastData?.map((media) => renderOvercastSource(media))
                        ) : (
                            <BasicAlert message="There was an error loading Overcast data." />
                        )}
                    </ConditionalSpinner>
                </section>
            </div>
        );
    }

    useEffect(() => {
        const video = videoRef?.current;

        if (!video || !selectedFile?.name) {
            props.validityChanged(false);
            return;
        }

        URL.revokeObjectURL(video.src);
        video.src = URL.createObjectURL(selectedFile);
        props.validityChanged(true);

        const handleCanPlay = () => {
            setIsSupported(true);
        };
        const handleError = () => {
            setIsSupported(false);
        };

        video.addEventListener("canplay", handleCanPlay);
        video.addEventListener("error", handleError);

        return () => {
            video?.removeEventListener("canplay", handleCanPlay);
            video?.removeEventListener("error", handleError);
        };
    }, [selectedFile?.name]);

    return (
        <div className="gap-4 d-flex flex-column">
            {showOvercastLibrary ? (
                renderOvercastSourceLibrary()
            ) : (
                <>
                    {isFileSelected && renderSelectedSource()}

                    <div className="gap-3 d-flex">
                        <Form.Label className="m-0 btn btn-primary">
                            <Form.Control
                                accept="video/*"
                                type="file"
                                onChange={handleFileSelected}
                                hidden
                                aria-hidden
                            />
                            Select File...
                        </Form.Label>
                        <Button onClick={handleShowOvercastSelection}>Select From Overcast Library</Button>
                    </div>
                </>
            )}
        </div>
    );
}

export default SourceSelectionPage;
