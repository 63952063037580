import { Spinner } from "react-bootstrap";
import { useGetProjectsQuery } from "../store/api/kinesense";
import "./StatusBar.scss";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../store";
import * as GeneralStore from "../store/general/General";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { Notifications } from "../utilities/Notifications/Notifications";
import Select from "react-select";

type ReactSelectOption = { value: string; label: string; mediaCount: number };

function StatusBar() {
    const dispatch = useDispatch();
    const { general, user } = useSelector((state: ApplicationState) => state);
    const activeProjectId = general?.activeProjectId;
    const username = user?.username;

    // TODO: handle pagination
    const { data: projects, isSuccess, isFetching } = useGetProjectsQuery({});
    const hasLoadedProjects = isSuccess && !isFetching;
    const hasLoaded = hasLoadedProjects;

    function handleOnSelectedProject(opt: { value: string; label: string }) {
        dispatch(GeneralStore.actionCreators.setActiveProject(opt.value));
    }

    function handleOnClickNotifications() {
        if (Notifications.hasNotifications() || Notifications.isShowingOldNotifications()) {
            Notifications.clearNotifications();
        } else {
            Notifications.showOldNotifications();
        }
    }

    const options: ReactSelectOption[] = (projects ?? []).map((p) => ({
        value: p.projectId,
        label: p.name,
        mediaCount: p.mediaCount,
    }));
    const selectedOption = options.find((p) => p.value == activeProjectId);

    const formatLabel = (data: ReactSelectOption) => (
        <div>
            <div className="fw-bold">{data.label}</div>
            <div className="second-line fst-italic">{data.mediaCount} Videos</div>
        </div>
    );

    return (
        <footer className="shadow-sm-y status-bar bg-body-tertiary w-100">
            <div className="px-4 d-flex h-100 justify-content-between align-items-center">
                <div className="gap-4 d-flex align-items-center">
                    <div className="gap-2 text-muted d-flex align-items-center">
                        <span className="fst-italic lh-sm">Active project:</span>
                        {hasLoaded ? (
                            <Select
                                classNamePrefix="react-select"
                                onChange={handleOnSelectedProject}
                                menuPlacement="top"
                                options={options}
                                formatOptionLabel={formatLabel}
                                value={selectedOption}
                            />
                        ) : (
                            <Spinner size="sm"></Spinner>
                        )}
                    </div>

                    <div className="gap-2 d-flex text-muted">
                        <span className="fst-italic">User:</span>
                        <span className="fw-bold">{username}</span>
                    </div>
                </div>

                <div>
                    <button className="button-with-icon" onClick={handleOnClickNotifications}>
                        <FontAwesomeIcon icon={faBell} />
                    </button>
                </div>
            </div>
        </footer>
    );
}

export default StatusBar;
