import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Fragment } from "cloud-core/analytics/Fragment";
import { AreaBounds } from "cloud-core/spatial/Spatial";
import { MutableRefObject, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useHover from "../../../hooks/useHover";
import useInterval from "../../../hooks/useInterval";
import useUserConfig from "../../../hooks/useUserConfig";
import { useView } from "../../../hooks/useView";
import { calculateFramePeriod } from "../../../models/media/MediaSource";
import { Entity } from "../../../models/viz/Entity";
import { ApplicationState } from "../../../store";
import { useGetMediaSourceQuery } from "../../../store/api/kinesense";
import { isBoundsElementsValid, isInRange } from "../../../utilities/helpers";
import { FragmentsMap } from "./useLoadFragments";
import { GlobalVideoImageSelector } from "../../../utilities/videoImageSelector/VideoImageSelector";
import ReactCrop, { PercentCrop } from "react-image-crop";
import { AreaBoundsEntityFilterParameter } from "../../../models/viz/filters/AreaBoundsEntityFilter";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { MediaSourceWithRunSummariesAndEndsAt } from "../../../store/media/MediaItems";

// Used for calculating corner sizes based on overlay/canvas width
const BOUNDING_BOX_RATIO_SIZE = 200;
const BOUNDING_BOX_RATIO_OFFSET = 110;

function drawFilterAreas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, areas: AreaBounds[]) {
    ctx.fillStyle = "rgba(255, 165, 0, 0.1)";
    ctx.strokeStyle = "rgba(255, 165, 0, 0.5)";

    for (const bounds of areas) {
        const [x1, y1, x2, y2] = convertBounds(canvas, bounds);
        const width = x2 - x1;
        const height = y2 - y1;
        ctx.fillRect(x1, y1, width, height);
        ctx.strokeRect(x1, y1, width, height);
    }
}

function drawBoundingBox(ctx: CanvasRenderingContext2D, bounds: AreaBounds, overlayWidth: number) {
    const [x1, y1, x2, y2] = bounds;
    const width = x2 - x1;
    const height = y2 - y1;
    const smallerDimension = Math.min(width, height);

    ctx.lineWidth = 2;
    ctx.lineJoin = "round";

    // DASHED LINE
    ctx.setLineDash([5, 5]);
    ctx.strokeStyle = "#ffffff";
    ctx.strokeRect(x1, y1, width, height);

    // FANCY CORNERS
    ctx.setLineDash([0]);
    ctx.strokeStyle = "orange";

    const offset = Math.max(overlayWidth / BOUNDING_BOX_RATIO_OFFSET, 5);
    let size = Math.max(overlayWidth / BOUNDING_BOX_RATIO_SIZE, 1);

    // Ensure gap between corners is at least as big as offset
    if (offset > smallerDimension - size * 2) {
        size = (smallerDimension - offset) / 2;
    }

    ctx.beginPath();

    // top left
    ctx.moveTo(x1 - offset, y1 + size);
    ctx.lineTo(x1 - offset, y1 - offset);
    ctx.lineTo(x1 + size, y1 - offset);

    // top right
    ctx.moveTo(x2 + offset, y1 + size);
    ctx.lineTo(x2 + offset, y1 - offset);
    ctx.lineTo(x2 - size, y1 - offset);

    // bottom left
    ctx.moveTo(x1 - offset, y2 - size);
    ctx.lineTo(x1 - offset, y2 + offset);
    ctx.lineTo(x1 + size, y2 + offset);

    // bottom right
    ctx.moveTo(x2 + offset, y2 - size);
    ctx.lineTo(x2 + offset, y2 + offset);
    ctx.lineTo(x2 - size, y2 + offset);

    ctx.stroke();
}

function drawFragmentId(ctx: CanvasRenderingContext2D, bounds: AreaBounds, fragmentId: string, canvasWidth: number) {
    const [x1, y1, x2, y2] = bounds;

    // Set style(s) which affect text measurements first
    ctx.font = "14px sans-serif";

    // Text measurements
    const textMetrics = ctx.measureText(fragmentId);
    const textWidth = textMetrics.width;
    const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
    const halfTextWidth = textWidth / 2;
    const centrePoint = (x2 + x1) / 2;
    const yMargin = 25;
    const yPadding = 10;
    const xPadding = 10;

    let textX = centrePoint - halfTextWidth;
    let textY = y1 - yMargin;

    // Adjust x if text dimensions will go off canvas
    if (centrePoint + halfTextWidth + xPadding > canvasWidth) {
        textX = canvasWidth - textWidth - xPadding;
    } else if (centrePoint - halfTextWidth - xPadding < 0) {
        textX = xPadding;
    }

    // Adjust y if text dimensions will go off canvas
    if (textY < 0) {
        textY = y2 + yPadding + yMargin;
    }

    // Draw text background
    ctx.fillStyle = "#00000088";
    ctx.fillRect(textX - xPadding, textY - yPadding * 2, textWidth + xPadding * 2, textHeight + yPadding * 2);

    // Draw fragmentId
    ctx.fillStyle = "#ffffff";
    ctx.fillText(fragmentId, textX, textY);
}

/** Calculate Fragment offset's absolute distance from the current cursor offset, in milliseconds */
function calculateFragmentDistance(frag: Fragment, cursorOffsetMilliseconds: number) {
    return Math.abs(frag.frameTimeOffset - cursorOffsetMilliseconds);
}

/** Convert the 0-1 bound values to be 0-(canvas dimensions) so the bounds can be drawn */
function convertBounds(canvas: HTMLCanvasElement, bounds: AreaBounds): AreaBounds {
    if (!isBoundsElementsValid(bounds)) {
        console.error(`The given AreaBounds object is not ordered correctly: ${bounds}`);

        const [x1, y1, x2, y2] = bounds;
        bounds = [Math.min(x1, x2), Math.min(y1, y2), Math.max(x1, x2), Math.max(y1, y2)];
    }

    const [x1, y1, x2, y2] = bounds;
    return [x1 * canvas.width, y1 * canvas.height, x2 * canvas.width, y2 * canvas.height];
}

/** Returns a maximum of 1 fragment per group based on how close it's offset time is to the cursor's offset */
function filterFragments(
    fragmentsMap: FragmentsMap,
    entitiesInView: Entity[],
    cursorOffsetMillisconds: number,
    isShowingDebugUi = false,
) {
    const filteredFrags: Fragment[] = [];
    for (const key in fragmentsMap) {
        const frags = fragmentsMap[key];

        if (frags.length == 0) {
            // With debug mode on, if group is empty, use group's bounds to display a fragment
            if (isShowingDebugUi) {
                for (const entity of entitiesInView) {
                    if (entity.sourceObject.id == key) {
                        filteredFrags.push(
                            new Fragment({
                                fragmentGroupId: entity.sourceObject.id,
                                fragmentId: entity.sourceObject.id,
                                bounds: entity.getBoundsOr([0, 0, 0, 0]),
                                frameTimeOffset: 0,
                                metadata: undefined,
                            }),
                        );
                        break;
                    }
                }
            }
            continue;
        }

        // Frags are sorted by offsetTime so there's no need to go through every frag,
        // just exit early when the distance to the cursor time starts increasing
        let prevFrag = frags[0];
        let prevDistance = calculateFragmentDistance(frags[0], cursorOffsetMillisconds);
        for (const frag of frags) {
            const currDistance = calculateFragmentDistance(frag, cursorOffsetMillisconds);

            if (currDistance <= prevDistance) {
                prevFrag = frag;
                prevDistance = currDistance;
                continue;
            }

            // Don't show frag if it's over 100ms away from the cursor time, even if it is the closest
            if (prevDistance <= 100) {
                filteredFrags.push(prevFrag);
            }

            break;
        }
    }

    return filteredFrags;
}

export interface VideoOverlayProps {
    viewId: string;
    media: MediaSourceWithRunSummariesAndEndsAt;
    canvasRef: React.MutableRefObject<HTMLCanvasElement>;
    videoRef: React.MutableRefObject<HTMLVideoElement>;
    videoPlayerOnTimeUpdate: () => void;
    fragmentsMap: MutableRefObject<FragmentsMap>;
    entitiesInView: Entity[];
    maxDimensions: [number, number];
}

function VideoOverlay(props: VideoOverlayProps) {
    const { view } = useView(props.viewId);

    const cursorMilliseconds = view.cursor;
    const cursorOffset = cursorMilliseconds - props.media.startsAt;

    const forceUpdate = useForceUpdate();

    const video = props.videoRef.current;
    const canvas = props.canvasRef.current;

    const [width, height] = props.maxDimensions;

    const { userConfig } = useUserConfig();
    const isShowingDebugUi = userConfig.showDebugUi;
    const { isHovering, mouseLocation } = useHover(props.canvasRef);

    const filterAreas = (view?.filters?.bounds?.params as AreaBoundsEntityFilterParameter)?.areas ?? [];

    const [isSelecting, setIsSelecting] = useState(GlobalVideoImageSelector.isSelecting);
    const [crop, setCrop] = useState<PercentCrop>({
        unit: "%",
        x: 0,
        y: 0,
        width: 50,
        height: 50,
    });

    function intervalCallback() {
        props.videoPlayerOnTimeUpdate();

        if (!canvas) {
            return;
        }

        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Don't draw the rest of the overlay while selection is active
        if (isSelecting) {
            return;
        }

        if (filterAreas.length > 0) {
            drawFilterAreas(canvas, context, filterAreas);
        }

        const filteredFrags = filterFragments(
            props.fragmentsMap.current,
            props.entitiesInView,
            cursorOffset,
            isShowingDebugUi,
        );

        for (const frag of filteredFrags) {
            const convertedBounds = convertBounds(canvas, frag.bounds);

            drawBoundingBox(context, convertedBounds, width);

            if (
                isShowingDebugUi &&
                isHovering &&
                // Mouse is hovering over bounding box if the below 2 conditions are true
                isInRange(mouseLocation[0], [convertedBounds[0], convertedBounds[2]]) &&
                isInRange(mouseLocation[1], [convertedBounds[1], convertedBounds[3]])
            ) {
                drawFragmentId(context, convertedBounds, frag.fragmentId, width);
            }
        }
    }

    const millisecondsBetweenFrames = calculateFramePeriod(props.media?.files?.display);
    const { pauseInterval, resumeInterval } = useInterval(intervalCallback, millisecondsBetweenFrames);

    // Control interval based on based on video element's events
    useEffect(() => {
        if (video === undefined || video === null) {
            return;
        }

        // pause interval on startup as video is not playing
        video.addEventListener("loadeddata", pauseInterval);
        video.addEventListener("play", resumeInterval);
        video.addEventListener("pause", pauseInterval);

        return () => {
            video.removeEventListener("loadeddata", pauseInterval);
            video.removeEventListener("play", resumeInterval);
            video.removeEventListener("pause", pauseInterval);
        };
    }, [video?.src]);

    // Update canvas based on mouse hovering, for showing debug information
    useEffect(() => {
        if (!isShowingDebugUi) {
            return;
        }

        intervalCallback();
    }, [mouseLocation]);

    const canvasElement = <canvas ref={props.canvasRef} />;

    useEffect(() => {
        GlobalVideoImageSelector.register(setIsSelecting, setCrop);
    }, []);

    useEffect(() => {
        if (isSelecting) {
            GlobalVideoImageSelector.renderComponent();
        } else {
            intervalCallback();
        }

        forceUpdate();
    }, [isSelecting]);

    if (!isSelecting) {
        return canvasElement;
    }

    return (
        <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => {
                setCrop(percentCrop);
            }}
            onComplete={(_, percentCrop) => {
                GlobalVideoImageSelector.setCrop(percentCrop);
            }}
        >
            {canvasElement}
        </ReactCrop>
    );
}

export default VideoOverlay;
