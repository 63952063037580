import { useDispatch } from "react-redux";
import { Entity } from "../../../../models/viz/Entity";
import * as AnalyticsStore from "../../../../store/analytics/Analytics";
import { VizListProps } from "./VizList";
import { LargeIconListItem } from "./LargeIconListItem";
import { useVizContext } from "../../../../models/viz/operations/DataOperation";
import { useGetFragmentImagesQuery } from "../../../../store/api/kinesense";
import useMediaSources from "../../../../hooks/useMediaSources";
import { FragmentGroup } from "../../../../models/media/FragmentGroup";
import { useMemo } from "react";

export function LargeIconList(props: VizListProps) {
    const dispatch = useDispatch();
    const context = useVizContext(props.viewId);
    const view = context.view;
    const { mediaId, activeMediaSource } = useMediaSources(props.viewId);
    const runId = activeMediaSource?.runs[activeMediaSource?.runs.length - 1]?.runId;

    function itemClicked(e: Entity) {
        dispatch(AnalyticsStore.actionCreators.setSelectedEntity(props.viewId, e));
    }

    // TODO: Revert changes when new backend for thumbnails is in place
    const midFragmentsMap = useMemo(() => {
        const midFragmentsMap = {};
        if (!context?.view?.entities?.length) {
            return midFragmentsMap;
        }

        for (const entity of context?.view?.entities ?? []) {
            const group = entity.sourceObject.object as FragmentGroup;
            if (group["fragmentGroupId"] !== undefined && group.info?.midFragmentId) {
                midFragmentsMap[group.fragmentGroupId] = [group.info.midFragmentId];
            }
        }
        return midFragmentsMap;
    }, [context?.view?.entities?.length]);

    const {
        data: fragmentImages,
        isSuccess: isSuccessFragmentImages,
        isFetching: isFetchingFragmentImages,
        isError: isErrorFragmentImages,
    } = useGetFragmentImagesQuery(
        {
            mediaId,
            runId,
            payload: { groups: midFragmentsMap },
        },
        { skip: mediaId === undefined || runId === undefined || !Object.keys(midFragmentsMap).length },
    );
    const hasLoadedFragmentImages = isSuccessFragmentImages && !isFetchingFragmentImages;

    return (
        <>
            {context.view?.filteredEntities?.map((e) => {
                const groupId = (e.sourceObject.object as FragmentGroup).fragmentGroupId;
                const shouldWaitForThumbnail =
                    !isErrorFragmentImages && groupId && midFragmentsMap[groupId] !== undefined;
                const thumbnailUrl =
                    shouldWaitForThumbnail && hasLoadedFragmentImages
                        ? fragmentImages?.imageUrls[groupId]?.[midFragmentsMap[groupId][0]]
                        : undefined;

                return (
                    <LargeIconListItem
                        key={e.id}
                        isSelected={e.id == view?.selectedEntity?.id}
                        onClick={itemClicked}
                        entity={e}
                        context={context}
                        shouldWaitForThumbnail={shouldWaitForThumbnail}
                        thumbnailUrl={thumbnailUrl}
                    />
                );
            })}
        </>
    );
}
