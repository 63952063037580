import { Fragment } from "cloud-core/analytics/Fragment";
import { useRef } from "react";
import useAsyncCallback from "../../../hooks/useAsyncCallback";
import useDebouncedCallback from "../../../hooks/useDebouncedCallback";
import { useView } from "../../../hooks/useView";
import { calculateFramePeriod } from "../../../models/media/MediaSource";
import { useLazyGetFragmentsFromGroupsQuery } from "../../../store/api/kinesense";
import { isInRange } from "../../../utilities/helpers";
import useMediaSources from "../../../hooks/useMediaSources";

export type FragmentsMap = { [key: string]: Fragment[] };

// Fragments from groups with start times - this value that overlap with the cursor time will still
// be loaded, so that fragments can be fetched in advance
const LOAD_AHEAD_MILLISECONDS = 3000;
// Values for the debounced callback which updates the fragmentsMap
const DEBOUNCE_MILLISECONDS = 50;
const DEBOUNCE_MAX_WAIT_MILLISECONDS = 2000;

function useLoadFragments(mediaId: string, viewId: string) {
    const { view } = useView(viewId);

    const { activeMediaSource: media } = useMediaSources(viewId, mediaId);
    const millisecondsBetweenFrames = calculateFramePeriod(media?.files?.display);

    // Used for accurate purging of old fragmentsMap data when the filter is updated
    const prevActiveFilterID = useRef(view?.activeFilterId);
    const prevFragmentGroupsLength = useRef(view?.filteredEntities.length);

    const [fetchFragments] = useLazyGetFragmentsFromGroupsQuery();

    const fragmentsMap = useRef<FragmentsMap>({});

    // Purge fragmentsMap of fragments from groups which have been filtered out
    function purgeFilteredFragments() {
        if (
            prevActiveFilterID.current !== view?.activeFilterId ||
            prevFragmentGroupsLength.current != view?.filteredEntities.length
        ) {
            const filteredFragmentsMap = { ...fragmentsMap.current };
            prevActiveFilterID.current = view?.activeFilterId;
            prevFragmentGroupsLength.current = view?.filteredEntities.length;

            const fragmentGroupIds = new Set(view?.filteredEntities.map((e) => e.sourceObject.id));
            for (const key in fragmentsMap.current) {
                if (!fragmentGroupIds.has(key)) {
                    delete filteredFragmentsMap[key];
                }
            }

            fragmentsMap.current = filteredFragmentsMap;
        }
    }

    // Update multiple groups in fragmentsMap based on the cursor time, only fetching groups
    // not already stored in the fragmentsMap (unless the activeFilterId has changed)
    async function updateFragmentsMap() {
        purgeFilteredFragments();

        // Group ids are gathered based on runId so fragments can be fetched in batches
        const runIdsMap: Record<string, [string, ...string[]]> = {};
        for (const entity of view?.filteredEntities ?? []) {
            if (fragmentsMap[entity.sourceObject.id] !== undefined) {
                continue;
            }

            const isInLoadRange = isInRange(view?.cursor, [
                entity.getStartTimeOr(new Date()).valueOf() - LOAD_AHEAD_MILLISECONDS,
                entity.getEndTimeOr(new Date()).valueOf() + millisecondsBetweenFrames,
            ]);

            if (
                isInLoadRange &&
                entity.sourceObject.mediaSource !== undefined &&
                entity.sourceObject.runId !== undefined
            ) {
                const runIdString = entity.sourceObject.runId.toString();
                if (runIdsMap[runIdString]) {
                    runIdsMap[runIdString].push(entity.sourceObject.id);
                } else {
                    runIdsMap[runIdString] = [entity.sourceObject.id];
                }
            }
        }

        // Update fragmentsMap with the newly fetched fragments
        for (const runIdString in runIdsMap) {
            const fetchedFragmentsMap = await fetchFragments({
                mediaId,
                runId: parseInt(runIdString),
                groupIds: runIdsMap[runIdString],
            }).unwrap();

            if (fetchedFragmentsMap) {
                fragmentsMap.current = Object.assign(fragmentsMap.current, fetchedFragmentsMap);
            }
        }
    }

    const debouncedUpdateFragmentsMap = useDebouncedCallback(updateFragmentsMap, DEBOUNCE_MILLISECONDS, {
        maxWait: DEBOUNCE_MAX_WAIT_MILLISECONDS,
        isImmediate: true,
    });

    useAsyncCallback(
        debouncedUpdateFragmentsMap,
        [view?.cursor, view?.filteredEntities, view?.filteredEntities.length],
        {
            skip: !mediaId,
        },
    );

    return fragmentsMap;
}

export default useLoadFragments;
