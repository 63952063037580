import { useMemo, useState } from "react";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import { useGetAnalysersConfigQuery, useGetAnalyticsMetadataQuery } from "../../../../store/api/kinesense";
import { Analyser, AnalyserConfigOptionValue } from "../../../../models/analysis/Config";
import SourceSelectionPage from "./pages/SourceSelectionPage";
import AlgorithmSelectionPage from "./pages/AlgorithmSelectionPage";
import NameAndDatePage from "./pages/NameAndDatePage";
import MetadataEntryPage from "./pages/MetadataEntryPage";
import SummaryPage from "./pages/SummaryPage";
import OptionsPage from "./pages/OptionsPage";
import { ImportMetadataResults } from "./ImportMetadata";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { UploadUtil, UploadUtilOptions } from "../fileUpload/UploadUtil";
import { IntegrationMediaSource, MediaSource } from "../../../../models/media/MediaSource";

interface ImportWizardPage {
    id: PageTypes;
    title: string;
    component: JSX.Element;
}
export interface ImportWizardPagePropsBase {
    validityChanged: (validity: boolean) => void;
    dataChanged: (data: unknown) => void;
}

export class ImportRequestPageData<T> {
    isValid: boolean;
    data: undefined | T;

    constructor() {
        this.isValid = false;
        this.data = undefined;
    }
}
export type NameAndDatePageData = { name: string; startTime: Date; timezoneOffset: number };
export type SourceSelectionPageData = {
    type: "library" | "file" | "integration";
    file?: File;
    integrationMediaSource?: IntegrationMediaSource;
    mediaSource: MediaSource;
};
export type OptionsPageData = {
    analyserOptions: { [key: string]: { [key: string]: AnalyserConfigOptionValue } };
    standardOptions: { [key: string]: AnalyserConfigOptionValue };
};

export type PageTypes = "source" | "algorithm" | "options" | "name" | "metadata" | "summary";
export type ImportRequestState = {
    source: ImportRequestPageData<SourceSelectionPageData>;
    algorithm: ImportRequestPageData<Analyser>;
    options: ImportRequestPageData<OptionsPageData>;
    name: ImportRequestPageData<NameAndDatePageData>;
    metadata: ImportRequestPageData<ImportMetadataResults>;
    summary: ImportRequestPageData<unknown>;
};

function ImportWizard() {
    const { data: analyserConfig, isLoading: isLoadingAnalysers } = useGetAnalysersConfigQuery();
    const { data: analyserMetadataConfig, isLoading: isLoadingMetadata } = useGetAnalyticsMetadataQuery({});
    const isLoading = isLoadingMetadata || isLoadingAnalysers;

    const [currentPage, setCurrentPage] = useState(0);
    const [isBackEnabled, setIsBackEnabled] = useState(true);
    const [uploader, setUploader] = useState<UploadUtil | undefined>(undefined);

    const [request, setRequest] = useState<ImportRequestState>({
        source: new ImportRequestPageData<SourceSelectionPageData>(),
        algorithm: new ImportRequestPageData(),
        options: new ImportRequestPageData(),
        name: new ImportRequestPageData<NameAndDatePageData>(),
        metadata: new ImportRequestPageData<ImportMetadataResults>(),
        summary: new ImportRequestPageData(),
    });

    const setValidity = (page: PageTypes, validity: boolean) => {
        const newRequest = { ...request };
        newRequest[page].isValid = validity;
        setRequest(newRequest);
    };

    const setData = (page: PageTypes, data: unknown) => {
        const newRequest = { ...request };
        newRequest[page].data = data;
        setRequest(newRequest);
    };

    const propsForPage = (page: PageTypes): ImportWizardPagePropsBase => {
        return {
            validityChanged: (validity) => {
                setValidity(page, validity);
            },
            dataChanged: (data) => {
                setData(page, data);
            },
        };
    };

    const handleGetUploader = () => {
        return uploader;
    };

    const handleCreateUploader = (options: UploadUtilOptions) => {
        const originalOnStart = options.onStart;
        options.onStart = () => {
            originalOnStart?.();
            setIsBackEnabled(false);
        };

        const fileUploader = new UploadUtil(options);
        setUploader(fileUploader);

        return fileUploader;
    };

    const createDefaultFileName = () => {
        const fileName = request.source.data?.file?.name ?? request.source.data?.integrationMediaSource?.name;

        if (!fileName) {
            return "Untitled";
        }

        const extensionIndex = fileName.lastIndexOf(".");
        if (extensionIndex > 0) {
            return fileName.substring(0, extensionIndex);
        }

        return fileName;
    };

    const videoFilePreviewUrl = useMemo(() => {
        return request?.source?.data?.file?.name !== undefined
            ? URL.createObjectURL(request.source.data.file)
            : undefined;
    }, [request?.source?.data?.file?.name]);

    const pages: ImportWizardPage[] = [
        {
            id: "source",
            title: "Select Data Source",
            component: (
                <SourceSelectionPage
                    selectedFile={request.source.data?.file}
                    value={request.source.data}
                    {...propsForPage("source")}
                />
            ),
        },
        {
            id: "algorithm",
            title: "Select Algorithm",
            component: (
                <AlgorithmSelectionPage
                    selectedAnalyser={request.algorithm.data}
                    analysers={analyserConfig?.analysers}
                    {...propsForPage("algorithm")}
                />
            ),
        },
        {
            id: "options",
            title: "Algorithm Configuration",
            component: (
                <OptionsPage
                    analyserConfig={analyserConfig}
                    selectedAnalyser={request.algorithm.data}
                    value={request.options.data}
                    previewImgUrl={request.source.data?.integrationMediaSource?.preview?.source}
                    previewVidUrl={videoFilePreviewUrl}
                    {...propsForPage("options")}
                />
            ),
        },
        {
            id: "name",
            title: "Name & Time",
            component: (
                <NameAndDatePage
                    value={request.name.data}
                    defaultFileName={createDefaultFileName()}
                    {...propsForPage("name")}
                />
            ),
        },
        {
            id: "metadata",
            title: "Information",
            component: (
                <MetadataEntryPage
                    analyserMetadata={analyserMetadataConfig}
                    value={request.metadata.data}
                    {...propsForPage("metadata")}
                />
            ),
        },
        {
            id: "summary",
            title: "Summary",
            component: (
                <SummaryPage request={request} getUploader={handleGetUploader} createUploader={handleCreateUploader} />
            ),
        },
    ];

    const handleNextClick = () => {
        const nextPage = Math.min(pages.length - 1, currentPage + 1);
        setCurrentPage(nextPage);
    };

    const handleBackClick = () => {
        const nextPage = Math.max(0, currentPage - 1);
        setCurrentPage(nextPage);
    };

    const renderButtons = () => {
        return (
            <>
                <Button
                    className="btn-secondary"
                    disabled={currentPage <= 0 || !isBackEnabled}
                    onClick={handleBackClick}
                >
                    Back
                </Button>
                <Button disabled={!request[pages[currentPage].id].isValid} onClick={handleNextClick}>
                    Next
                </Button>
            </>
        );
    };

    return (
        <Container>
            <Card className="import-wizard">
                <Card.Header>
                    <Container className="page-header">
                        <Row>
                            <Col as="h4" className="m-0 d-flex align-items-center">
                                {pages[currentPage].title}
                            </Col>
                            <Col md="auto">{renderButtons()}</Col>
                        </Row>
                    </Container>
                </Card.Header>
                <Card.Body>
                    <ConditionalSpinner isLoading={isLoading}>{pages[currentPage].component}</ConditionalSpinner>
                </Card.Body>
                <Card.Footer>
                    <div className="footer">{renderButtons()}</div>
                </Card.Footer>
            </Card>
        </Container>
    );
}

export default ImportWizard;
