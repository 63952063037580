import { DataOperation, VizContext } from "./DataOperation";
import { GlobalVideoImageSelector } from "../../../utilities/videoImageSelector/VideoImageSelector";
import { Button } from "react-bootstrap";
import { convertCropToBounds } from "../../../utilities/helpers";
import NiceModal from "@ebay/nice-modal-react";
import DownloadVideoFrameModal from "../../../components/dataOperationModals/downloadVidoeFrameModal/DownloadVideoFrameModal";

export class DownloadVideoFrameOperation extends DataOperation {
    constructor() {
        super();
        this.operationId = "downloadVideoFrame";
        this.displayName = "Download Video Frame";
        this.icon = {
            type: "image",
            url: "/images/icons/import-image_56.png",
        };
        this.priority = "primary";
        this.context = "selection";
        this.category = "export";
        NiceModal.register(this.operationId, DownloadVideoFrameModal);
    }

    canExecute(context: VizContext) {
        const entity = context.view?.selectedEntity ?? context.view?.entities[0];
        const media = entity?.sourceObject?.mediaSource;
        const fileId = media?.files?.display?.fileId;

        return fileId !== undefined && context.view.cursor !== undefined && context.container.hasInlineCropSelector;
    }

    async execute(context: VizContext) {
        GlobalVideoImageSelector.beginSelection(() => (
            <div className="gap-5 d-flex justify-content-between w-100">
                <Button
                    title="Expand selection to the entire frame"
                    onClick={() =>
                        GlobalVideoImageSelector.setComponentCrop({ unit: "%", x: 0, y: 0, width: 100, height: 100 })
                    }
                >
                    Expand
                </Button>

                <div className="gap-2 d-flex">
                    <Button
                        variant="secondary"
                        onClick={GlobalVideoImageSelector.endSelection}
                        title="Cancel selection"
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="success"
                        title="Confirm selection"
                        onClick={() =>
                            NiceModal.show(this.operationId, {
                                context,
                                selection: convertCropToBounds(GlobalVideoImageSelector.completeSelection()),
                            })
                        }
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        ));
    }
}
