import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { timestampJsonReviver } from "cloud-core/utilities/json";
import { ApplicationState } from "../";
import { Organisation } from "../../models/Organisation";
import { Role, User, convertFetchedUsers } from "../../models/User";
import { UserConfig } from "../../models/UserConfig";
import { Processor, ProcessorTypes } from "../../models/admin/Processor";
import { AnalyticsDefinition, AnalyticsMetadata } from "../../models/analysis/Config";
import { Run } from "../../models/analysis/Runs";
import { IntegrationProject } from "../../models/integrations/IntegrationProject";
import { AnalysisRequest } from "../../models/media/AnalysisRequest";
import { FetchedFragmentGroup } from "../../models/media/FragmentGroup";
import { Fragment } from "cloud-core/analytics/Fragment";
import { AddMediaSourceRequestBody, IntegrationMediaSource, MediaSource } from "../../models/media/MediaSource";
import { addAuthHeaders } from "../../utilities/Auth";
import { actionCreators } from "../user/User";
import { StoredFile } from "../../models/media/StoredFile";
import { FragmentsMap } from "../../views/playback/components/useLoadFragments";
import { MediaSourceWithRunSummariesAndEndsAt } from "../media/MediaItems";
import { Project } from "../../models/Project";
import { TagObject } from "../../models/tags/TagObject";
import { TagEvent } from "../../models/tags/TagEvent";

const TEXT_PLAIN_HEADER = { "Content-Type": "text/plain" } as const;

export interface BaseAdminQueryArgs {
    organisationId: string;
}
export type BaseAugmentationArgs = { organisationId?: string };
export type AugmentationWithPayloadArgs = BaseAugmentationArgs & { payload: string };
export type AugmentUserArgs = BaseAugmentationArgs & { username: string };

const tagTypes = [
    "userConfig",
    "projects",
    "users",
    "organisation",
    "organisations",
    "media",
    "processors",
    "fragmentGroups",
    "fragments",
    "runs",
    "tagObjects",
    "tagEvents",
    "analyticsMetadata",
    "tagObjects",
    "tagEvents",
] as const;
type TagType = (typeof tagTypes)[number];
interface Tag {
    type: TagType;
    id: string;
}

const usersTag: Tag = { type: "users", id: "0" };
const getUsersAdminTag = (args: BaseAugmentationArgs) => ({ type: "users", id: args.organisationId }) as Tag;

const baseQuery = fetchBaseQuery({
    baseUrl: `https://${process.env.REACT_APP_KINESENSE_API_BASE}`,
    prepareHeaders: (headers, { getState }) => {
        const state = getState() as ApplicationState;

        if (state && state.user !== undefined) {
            addAuthHeaders(state.user.authToken, headers);
        }

        return headers;
    },
});

export interface KinesenseApiResponse<T> {
    data: T;
    hasNextPage: boolean;
    pageKey: string | undefined;
}

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error?.status === 401) {
        api.dispatch(actionCreators.refreshAuthSession());

        result = await baseQuery(args, api, extraOptions);

        if (result.error?.status === 401) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            result = await baseQuery(args, api, extraOptions);
        }
    }

    const resultData = result.data as KinesenseApiResponse<unknown>;
    result.data = resultData?.data;

    return result;
};

export const kinesenseApiSlice = createApi({
    reducerPath: "api",
    baseQuery: baseQueryWithReauth,
    tagTypes: tagTypes,
    endpoints: (builder) => ({
        // PROJECTS
        getProjects: builder.query<Project[], { pageKey?: string }>({
            query: (args) => "/projects" + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
            providesTags: ["projects"],
        }),
        getProject: builder.query<Project, { projectId: string }>({
            query: (args) => `/projects/${args.projectId}`,
        }),
        getProjectThumbnails: builder.query<Record<string, string>, { projectId: string; pageKey?: string }>({
            query: (args) =>
                `/projects/${args.projectId}/media/get-thumbnails` + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
        }),
        addProject: builder.mutation<unknown, { payload: string }>({
            query: (args) => ({
                url: "/projects",
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                // Payload already stringified
                body: args.payload,
            }),
            invalidatesTags: ["projects"],
        }),
        // USERS
        getCurrentUserConfig: builder.query<UserConfig, void>({
            query: () => "/user/config/general",
            providesTags: ["userConfig"],
        }),
        updateCurrentUserConfig: builder.mutation<void, Partial<UserConfig>>({
            query: (args) => ({ url: "/user/config/general", method: "PATCH", body: JSON.stringify(args) }),
            invalidatesTags: ["userConfig"],
        }),

        getUsers: builder.query<User[], { pageKey?: string }>({
            query: (args) => {
                return "/organisation/users" + (args?.pageKey ? `?pageKey=${args.pageKey}` : "");
            },
            transformResponse: convertFetchedUsers,
            providesTags: [usersTag],
        }),
        adminGetUsers: builder.query<User[], BaseAdminQueryArgs & { pageKey?: string }>({
            query: (args) =>
                `/admin/organisations/${args.organisationId}/users` + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
            transformResponse: convertFetchedUsers,
            providesTags: (_, __, admin_query_args) => [getUsersAdminTag(admin_query_args)],
        }),

        addUser: builder.mutation<unknown, AugmentationWithPayloadArgs>({
            query: (args) => ({
                url: "/organisation/users",
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                // Payload already stringified
                body: args.payload,
            }),
            invalidatesTags: [usersTag],
        }),
        adminAddUser: builder.mutation<unknown, AugmentationWithPayloadArgs>({
            query: (args) => ({
                url: `/admin/organisations/${args.organisationId}/users`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                // Payload already stringified
                body: args.payload,
            }),
            invalidatesTags: (_, __, augmentation_args) => [getUsersAdminTag(augmentation_args)],
        }),

        deleteUser: builder.mutation<void, AugmentUserArgs>({
            query: (args) => ({
                url: `/organisation/users/${args.username}`,
                method: "DELETE",
            }),
            invalidatesTags: [usersTag],
        }),
        adminDeleteUser: builder.mutation<void, AugmentUserArgs>({
            query: (args) => ({
                url: `/admin/organisations/${args.organisationId}/users/${args.username}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, augment_user_args) => [getUsersAdminTag(augment_user_args)],
        }),

        resetUserPassword: builder.mutation<void, AugmentUserArgs>({
            query: (args) => ({
                url: `/organisation/users/${args.username}/reset-password`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
            }),
            invalidatesTags: [usersTag],
        }),
        adminResetUserPassword: builder.mutation<void, AugmentUserArgs>({
            query: (args) => ({
                url: `/admin/organisations/${args.organisationId}/users/${args.username}/reset-password`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
            }),
            invalidatesTags: (_, __, augment_user_args) => [getUsersAdminTag(augment_user_args)],
        }),

        adminSetUserRole: builder.mutation<void, AugmentUserArgs & { body: { role: Role } }>({
            query: (args) => ({
                url: `/admin/organisations/${args.organisationId}/users/${args.username}/role`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: args.body,
            }),
            invalidatesTags: (_, __, augment_user_args) => [getUsersAdminTag(augment_user_args)],
        }),

        // ORGANISATIONS
        getOrganisation: builder.query<Organisation, void>({
            query: () => "/organisation",
            providesTags: ["organisation"],
        }),

        adminGetOrganisations: builder.query<Organisation[], { pageKey?: string }>({
            query: (args) => "/admin/organisations" + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
            providesTags: ["organisations"],
        }),

        adminAddOrganisation: builder.mutation<Organisation, AugmentationWithPayloadArgs>({
            query: (args) => ({
                url: "/admin/organisations",
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: args.payload,
            }),
            invalidatesTags: ["organisations"],
        }),

        // MEDIA
        getAllMedia: builder.query<MediaSource[], { projectId: string; pageKey?: string }>({
            query: (args) => `/projects/${args.projectId}/media` + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
            providesTags: ["media"],
        }),
        addMediaSource: builder.mutation<MediaSource, AddMediaSourceRequestBody>({
            query: (mediaSource) => ({
                url: `/projects/${mediaSource.projectId}/media`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(mediaSource),
                responseHandler: (response) =>
                    response
                        .text()
                        .then((txt) => JSON.parse(txt, timestampJsonReviver(["startsAt", "createdAt", "updatedAt"]))),
            }),
            invalidatesTags: ["media"],
        }),
        getMediaSource: builder.query<MediaSourceWithRunSummariesAndEndsAt, { projectId: string; mediaId: string }>({
            query: (args) => `/projects/${args.projectId}/media/${args.mediaId}`,
            transformResponse: (fetchedMediaSource: MediaSourceWithRunSummariesAndEndsAt) => {
                fetchedMediaSource.endsAt = fetchedMediaSource.startsAt + fetchedMediaSource.duration;
                return fetchedMediaSource;
            },
        }),

        getStoredFile: builder.query<StoredFile, { fileId: string }>({
            query: (args) => `/files/${args.fileId}`,
        }),

        // INTEGRATIONS
        getAllIntegrationMedia: builder.query<IntegrationMediaSource[], { integrationId: string }>({
            query: (args) => `/integrations/${args.integrationId}/media`,
        }),
        requestIntegrationTransfer: builder.mutation<
            void,
            { integrationId: string; integrationMediaId: string; mediaId: string }
        >({
            query: (args) => ({
                url: `/integrations/${args.integrationId}/start-transfer`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify({
                    integrationMediaId: args.integrationMediaId,
                    linkedObjectId: args.mediaId,
                    linkedObjectType: "mediasource",
                }),
            }),
        }),
        getAllIntegrationProjects: builder.query<IntegrationProject[], { integrationId: string }>({
            query: (args) => `/integrations/${args.integrationId}/projects`,
        }),
        exportMediaToIntegration: builder.mutation<
            string,
            {
                mediaId: string;
                projectId: string;
                integrationId: string;
                title: string;
                description: string;
                startsAt: number;
                endsAt: number;
                integrationProjectId: string;
                folderId: string;
            }
        >({
            query: (args) => ({
                url: `/projects/${args.projectId}/media/${args.mediaId}/export-clip`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                responseHandler: (response) => response.text(),
                body: JSON.stringify({
                    integrationId: args.integrationId,
                    title: args.title,
                    description: args.description,
                    startsAt: args.startsAt,
                    endsAt: args.endsAt,
                    projectId: args.integrationProjectId,
                    folderId: args.folderId,
                }),
            }),
        }),

        // ANALYSIS
        getRuns: builder.query<Run[], { mediaId: string }>({
            query: (args) => `/media/${args.mediaId}/analytics/runs`,
            providesTags: (_result, _error, args) => [{ type: "runs", id: args.mediaId }],
        }),
        getFragments: builder.query<Fragment[], { mediaId: string; runId: number; groupId: string; pageKey?: string }>({
            query: (args) =>
                `/media/${args.mediaId}/analytics/runs/${args.runId}/groups/${args.groupId}/fragments` +
                (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
            providesTags: (_result, _error, args) => [{ type: "fragments", id: `${args.runId}-${args.groupId}` }],
            transformResponse: (fetchedFragments: Fragment[]) =>
                fetchedFragments.sort((a, b) => a.frameTimeOffset - b.frameTimeOffset),
        }),
        getFragmentsFromGroups: builder.query<
            FragmentsMap,
            { mediaId: string; runId: number; groupIds: [string, ...string[]] }
        >({
            query: (args) =>
                `/media/${args.mediaId}/analytics/runs/${args.runId}/groups/fragments?groupId=${args.groupIds.join(
                    ",",
                )}`,
            providesTags: (_result, _error, args) => [
                { type: "fragments", id: `${[...args.groupIds].sort((a, b) => a.localeCompare(b)).join(",")}` },
            ],
            transformResponse: (fetchedFragmentsMap: FragmentsMap) => {
                // Sort each array of fragments by their frameTimeOffset
                const sortedMap: FragmentsMap = {};
                for (const groupId in fetchedFragmentsMap) {
                    sortedMap[groupId] = fetchedFragmentsMap[groupId].sort(
                        (a, b) => a.frameTimeOffset - b.frameTimeOffset,
                    );
                }
                return sortedMap;
            },
        }),
        getFragmentGroups: builder.query<FetchedFragmentGroup[], { mediaId: string; runId: number; pageKey?: string }>({
            query: (args) =>
                `/media/${args.mediaId}/analytics/runs/${args.runId}/groups` +
                (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
            providesTags: (_result, _error, args) => [{ type: "fragmentGroups", id: `${args.mediaId}-${args.runId}` }],
            keepUnusedDataFor: 180,
        }),
        getFragmentImages: builder.query<
            { imageUrls: { [groupId: string]: { [fragmentId: string]: string } } },
            { mediaId: string; runId: number; payload: { groups: { [groupId: string]: string[] } }; pageKey?: string }
        >({
            query: (args) => ({
                url:
                    `/media/${args.mediaId}/analytics/runs/${args.runId}/get-fragment-images` +
                    (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.payload),
            }),
        }),

        getAnalysersConfig: builder.query<AnalyticsDefinition, void>({
            query: () => "/organisation/config/analysers",
        }),
        getAnalyticsMetadata: builder.query<AnalyticsMetadata, { pageKey?: string }>({
            query: (args) => "/organisation/config/aMetadata" + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
            providesTags: ["analyticsMetadata"],
        }),
        setAnalyticsMetadata: builder.mutation<void, AnalyticsMetadata>({
            query: (args) => ({ url: "/organisation/config/aMetadata", method: "PUT", body: JSON.stringify(args) }),
        }),
        deleteAnalyticsMetadataGroup: builder.mutation<void, { groupId: string }>({
            query: (args) => ({ url: `/organisation/config/aMetadata/${args.groupId}`, method: "DELETE" }),
        }),
        requestAnalysis: builder.mutation<Run, { mediaId: string; analysisRequest: AnalysisRequest }>({
            query: (args) => ({
                url: `/media/${args.mediaId}/queue-analysis`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.analysisRequest),
            }),
            // TODO - ROLV: invalidate tags
        }),
        linkFileToMediaSource: builder.mutation<void, { projectId: string; mediaId: string; fileId: string }>({
            query: (args) => ({
                url: `/projects/${args.projectId}/media/${args.mediaId}/link-file`,
                method: "POST",
                body: JSON.stringify({ fileId: args.fileId }),
            }),
        }),

        // TAGS
        getAllTagObjects: builder.query<TagObject[], { projectId: string; pageKey?: string }>({
            query: (args) =>
                `/projects/${args.projectId}/tags/objects` + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
            providesTags: ["tagObjects"],
        }),
        createTagObject: builder.mutation<TagObject, { projectId: string; tagObject: Partial<TagObject> }>({
            query: (args) => ({
                url: `/projects/${args.projectId}/tags/objects`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.tagObject),
            }),
            invalidatesTags: ["tagObjects"],
        }),
        updateTagObject: builder.mutation<
            TagObject,
            { projectId: string; tagObjectId: string; tagObject: Partial<TagObject> }
        >({
            query: (args) => ({
                url: `/projects/${args.projectId}/tags/objects/${args.tagObjectId}`,
                method: "PATCH",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.tagObject),
            }),
            invalidatesTags: ["tagObjects"],
        }),
        getAllTagEvents: builder.query<TagEvent[], { projectId: string; pageKey?: string }>({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                // Populate each tag event with its associated tag objects
                const objectsResult = await fetchWithBQ(`/projects/${args.projectId}/tags/objects`);
                if (objectsResult.error) {
                    return { error: objectsResult.error as FetchBaseQueryError };
                }
                const objects = objectsResult.data as TagObject[];

                // Get all possible tag objects
                // TODO: handle pagination
                /* let hasNextPage = objectsResult.meta["hasNextPage"] ?? false; */
                /* let pageKey = 1; */
                /* while (hasNextPage) { */
                /*     const objectsResult = await fetchWithBQ( */
                /*         `/projects/${args.projectId}/tags/objects?pageKey=${pageKey}`, */
                /*     ); */
                /*     if (objectsResult.error) return { error: objectsResult.error as FetchBaseQueryError }; */
                /**/
                /*     pageKey += 1; */
                /*     hasNextPage = objectsResult.meta["hasNextPage"] ?? false; */
                /**/
                /*     objects.concat(objectsResult.data as TagObject[]); */
                /* } */

                const eventsResult = await fetchWithBQ(
                    `/projects/${args.projectId}/tags/events` + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
                );

                if (eventsResult.error || !eventsResult.data) {
                    return { error: eventsResult.error as FetchBaseQueryError };
                }

                const data = eventsResult.data as TagEvent[];
                for (const event of data) {
                    event.subjects = [];
                    event.objects = [];
                    event.locations = [];
                    for (const object of objects) {
                        if (event.subjectIds.includes(object.objectId)) {
                            event.subjects.push(object);
                        } else if (event.objectIds.includes(object.objectId)) {
                            event.objects.push(object);
                        } else if (event.locationIds.includes(object.objectId)) {
                            event.locations.push(object);
                        }
                    }
                }

                return { data: eventsResult.data as TagEvent[] };
            },
            providesTags: ["tagEvents"],
        }),
        createTagEvent: builder.mutation<TagEvent, { projectId: string; tagEvent: Partial<TagEvent> }>({
            query: (args) => ({
                url: `/projects/${args.projectId}/tags/events`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.tagEvent),
            }),
            invalidatesTags: ["tagEvents"],
        }),
        updateTagEvent: builder.mutation<
            TagEvent,
            { projectId: string; tagEventId: string; tagEvent: Partial<TagEvent> }
        >({
            query: (args) => ({
                url: `/projects/${args.projectId}/tags/events/${args.tagEventId}`,
                method: "PATCH",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.tagEvent),
            }),
            invalidatesTags: ["tagEvents"],
        }),

        // PROCESSORS
        adminGetProcessor: builder.query<Processor, { processorId: string }>({
            query: (args) => `/analysis/processors/${args.processorId}`,
        }),

        adminGetAllProcessors: builder.query<Processor[], void>({
            query: () => "/analysis/processors",
            providesTags: ["processors"],
        }),

        adminGetProcessorTypes: builder.query<ProcessorTypes, void>({
            query: () => "/analysis/processor-types",
        }),

        adminAddProcessor: builder.mutation<Processor, { type: string }>({
            query: (args) => ({
                url: "/analysis/processors",
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args),
            }),
            invalidatesTags: ["processors"],
        }),
    }),
});

export const {
    endpoints,
    useGetProjectsQuery,
    useGetProjectQuery,
    useGetProjectThumbnailsQuery,
    useAddProjectMutation,
    useLazyGetCurrentUserConfigQuery,
    useGetCurrentUserConfigQuery,
    useGetUsersQuery,
    useUpdateCurrentUserConfigMutation,
    useAdminGetUsersQuery,
    useAddUserMutation,
    useAdminAddUserMutation,
    useDeleteUserMutation,
    useAdminDeleteUserMutation,
    useResetUserPasswordMutation,
    useAdminResetUserPasswordMutation,
    useGetOrganisationQuery,
    useAdminGetOrganisationsQuery,
    useAdminAddOrganisationMutation,
    useGetAllMediaQuery,
    useLazyGetAllMediaQuery,
    useGetStoredFileQuery,
    useAdminGetAllProcessorsQuery,
    useAdminGetProcessorQuery,
    useAdminGetProcessorTypesQuery,
    useAdminAddProcessorMutation,
    useAdminSetUserRoleMutation,
    useGetFragmentsQuery,
    useLazyGetFragmentsQuery,
    useLazyGetFragmentGroupsQuery,
    useGetFragmentGroupsQuery,
    useGetFragmentImagesQuery,
    useLazyGetFragmentsFromGroupsQuery,
    useGetAnalysersConfigQuery,
    useGetAnalyticsMetadataQuery,
    useSetAnalyticsMetadataMutation,
    useDeleteAnalyticsMetadataGroupMutation,
    useGetRunsQuery,
    useLazyGetRunsQuery,
    useAddMediaSourceMutation,
    useRequestAnalysisMutation,
    useGetAllIntegrationMediaQuery,
    useRequestIntegrationTransferMutation,
    useGetAllIntegrationProjectsQuery,
    useExportMediaToIntegrationMutation,
    useGetMediaSourceQuery,
    useLazyGetMediaSourceQuery,
    useGetAllTagObjectsQuery,
    useCreateTagObjectMutation,
    useUpdateTagObjectMutation,
    useGetAllTagEventsQuery,
    useCreateTagEventMutation,
    useUpdateTagEventMutation,
    useLinkFileToMediaSourceMutation,
} = kinesenseApiSlice;
