import dayjs from "dayjs";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DateFormats } from "../../../../utilities/dates";
import { MediaSource } from "../../../../models/media/MediaSource";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { MEDIA_ID_QUERY_KEY, PROJECT_ID_QUERY_KEY } from "../../../../hooks/useManageViews";

export interface VideoListItemProps {
    data: MediaSource;
}

function VideoListItem(props: VideoListItemProps) {
    const projectId = useSelector((state: ApplicationState) => state.general?.activeProjectId);

    return (
        <ListGroup.Item
            action
            as={Link}
            to={`/review?${PROJECT_ID_QUERY_KEY}=${projectId}&${MEDIA_ID_QUERY_KEY}=${props.data.mediaId}`}
        >
            <img height="24" src="/images/icons/report-video_56.png" alt="video" />
            <span className="text-truncate flex-fill" title={props.data.name}>
                {props.data.name}
            </span>
            <span className="text-secondary">
                {dayjs(props.data.startsAt).format(DateFormats.yearMonthDayWithTime)}
            </span>
        </ListGroup.Item>
    );
}

export default VideoListItem;
